import styled from 'styled-components'
import useTheme from '@/src/ui/theme'
import Spinner from './Spinner'
import { Typography, TypographyWeight } from './Typography'
import { FlexCenter } from './Flex'
import { Icon, IconType } from './Icon'
import { useSegment } from '@parafin/logging'

type LoadingProp = {
  $loading?: boolean
}

const ButtonContent = styled(FlexCenter)<LoadingProp>`
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
  transition: all 0s;
`

const SpinnerContainer = styled.div<LoadingProp>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  ${({ $loading }) => `
    display: ${$loading ? 'block' : 'none'};
  `}
`

type BaseButtonProps = {
  color?: string
  backgroundColor?: string
  hoverColor?: string
  hoverBackgroundColor?: string
  borderColor?: string
  padding?: 'big' | 'small'
}

const BaseButton = styled.button<BaseButtonProps & LoadingProp>`
  border: 1px solid ${({ borderColor }) => borderColor ?? 'transparent'};
  border-radius: 4px;
  padding: ${({ padding }) => (padding === 'small' ? '0px' : '8px 16px')};
  cursor: pointer;
  width: fit-content;
  position: relative;
  color: ${({ color, theme }) => color ?? theme.colors.basil100};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  &:hover:not(:disabled) {
    ${({ hoverColor, hoverBackgroundColor, $loading }) =>
      !$loading &&
      `
      color: ${hoverColor};
      background-color: ${hoverBackgroundColor};
    `}
  }
  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
  transition: all 0s;
`

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary'
export type ButtonColor = 'green' | 'red' | 'brown' | 'gray'

const useColorsForVariant = (
  variant: ButtonVariant,
  color: ButtonColor
): BaseButtonProps => {
  const { theme } = useTheme()
  switch (variant) {
    case 'primary':
      switch (color) {
        case 'green':
          return {
            color: theme.colors.white,
            backgroundColor: theme.colors.basil100,
            hoverBackgroundColor: theme.colors.kale100,
          }
        case 'gray':
          return {
            color: theme.colors.black,
            backgroundColor: theme.colors.black20,
            hoverBackgroundColor: theme.colors.black30,
          }
        case 'brown':
          return {
            color: theme.colors.white,
            backgroundColor: theme.colors.mustard90,
            hoverBackgroundColor: theme.colors.mustard100,
          }
        case 'red':
          return {
            color: theme.colors.white,
            backgroundColor: theme.colors.reed100,
            hoverBackgroundColor: theme.colors.reed140,
          }
      }
    case 'secondary':
      switch (color) {
        case 'green':
          return {
            color: theme.colors.basil100,
            borderColor: theme.colors.black30,
          }
        case 'gray':
          return {
            color: theme.colors.black,
            borderColor: theme.colors.black30,
          }
        case 'brown':
          return {
            color: theme.colors.mustard90,
            borderColor: theme.colors.black30,
          }
        case 'red':
          return {
            color: theme.colors.reed100,
            borderColor: theme.colors.black30,
          }
      }
    case 'tertiary':
      switch (color) {
        case 'green':
          return {
            color: theme.colors.basil100,
            hoverColor: theme.colors.kale100,
            padding: 'small',
          }
        case 'gray':
          return {
            color: theme.colors.black80,
            hoverColor: theme.colors.black100,
            padding: 'small',
          }
        case 'brown':
          return {
            color: theme.colors.mustard90,
            hoverColor: theme.colors.mustard100,
            padding: 'small',
          }
        case 'red':
          return {
            color: theme.colors.reed100,
            hoverColor: theme.colors.reed140,
            padding: 'small',
          }
      }
  }
}

type ButtonProps = {
  id: string
  loading?: boolean
  variant?: ButtonVariant
  color?: ButtonColor
  leftIcon?: IconType
  rightIcon?: IconType
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  type = 'button',
  loading,
  variant = 'primary',
  color = 'green',
  leftIcon,
  rightIcon,
  id,
  children,
  onClick,
  ...props
}: ButtonProps) => {
  const { logInteraction } = useSegment()
  const variantProps = useColorsForVariant(variant, color)
  const buttonProps = { ...variantProps, ...props, type, $loading: loading }

  return (
    <BaseButton
      disabled={loading || props.disabled}
      {...buttonProps}
      onClick={(e) => {
        logInteraction(`button-${id}`, 'click')
        onClick?.(e)
      }}
    >
      <ButtonContent $loading={loading} gap="8px">
        {leftIcon && <Icon type={leftIcon} />}
        <Typography weight="medium">{children}</Typography>
        {rightIcon && <Icon type={rightIcon} />}
      </ButtonContent>
      <SpinnerContainer $loading={loading}>
        <Spinner size="14px" border={2} variant={variant} color={color} />
      </SpinnerContainer>
    </BaseButton>
  )
}

export default Button
