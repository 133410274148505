import { useQuery as c, useMutation as i } from "@tanstack/react-query";
import a from "axios";
const d = (t, e) => a.get(
  "/echo",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), p = (t) => ["/echo", ...t ? [t] : []], _ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? p(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function g(t, e) {
  const n = _(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const m = (t, e) => a.post(
  "/echo",
  t,
  e
), l = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return m(o, n);
  }, ...e };
}, q = (t) => {
  const e = l(t);
  return i(e);
}, Jl = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getEcho: d,
  getGetEchoQueryKey: p,
  getGetEchoQueryOptions: _,
  getPostEchoMutationOptions: l,
  postEcho: m,
  useGetEcho: g,
  usePostEcho: q
}, Symbol.toStringTag, { value: "Module" })), tg = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  ordercreated: "order.created",
  orderupdated: "order.updated",
  refundcreated: "refund.created",
  refundupdated: "refund.updated",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  wallet_applicationapproved: "wallet_application.approved",
  wallet_applicationdenied: "wallet_application.denied",
  wallet_accountactive: "wallet_account.active",
  wallet_accountinactive: "wallet_account.inactive",
  wallet_transactionsucceeded: "wallet_transaction.succeeded",
  wallet_transactionpending: "wallet_transaction.pending",
  wallet_transactioncanceled: "wallet_transaction.canceled",
  wallet_transactionarchived: "wallet_transaction.archived",
  wallet_transactionfailed: "wallet_transaction.failed"
}, eg = {
  normal: "normal",
  sync: "sync"
}, ng = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, rg = {
  enabled: "enabled",
  disabled: "disabled"
}, sg = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, og = {
  active: "active",
  inactive: "inactive"
}, ag = {
  read: "read",
  unread: "unread"
}, ug = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, ig = {
  Error: "Error",
  Warning: "Warning"
}, cg = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, yg = {
  pending_underwriting: "pending_underwriting",
  underwriting: "underwriting",
  failed: "failed",
  success: "success"
}, dg = {
  failed_plaid_auth: "failed_plaid_auth"
}, pg = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, _g = {
  pending_kitchen_response: "pending_kitchen_response",
  pending_kitchen_underwriting: "pending_kitchen_underwriting",
  failed: "failed",
  success: "success"
}, mg = {
  failed_plaid_auth: "failed_plaid_auth",
  no_plaid_dispersement_observed: "no_plaid_dispersement_observed"
}, lg = {
  pst: "pst",
  est: "est",
  utc: "utc"
}, gg = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, qg = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, fg = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AS: "AS",
  GU: "GU",
  MP: "MP",
  PR: "PR",
  VI: "VI",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, Og = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, hg = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
  term: "term"
}, Kg = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, Pg = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, bg = {
  pending_response: "pending_response",
  completed: "completed"
}, xg = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, kg = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table"
}, Mg = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, Fg = {
  shopify: "shopify",
  amazon: "amazon",
  square: "square",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  quickbooks: "quickbooks",
  xero: "xero",
  net_suite: "net_suite",
  magneto: "magneto",
  freshbooks: "freshbooks",
  zohobooks: "zohobooks",
  clover: "clover",
  paypal: "paypal",
  sage_cloud: "sage_cloud",
  sage_intacct: "sage_intacct"
}, Rg = {
  payments: "payments",
  accounting: "accounting"
}, Bg = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, Cg = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, Eg = {
  offer_amount: "offer_amount",
  fee_amount: "fee_amount"
}, vg = {
  regular: "regular",
  special: "special"
}, Sg = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, Ag = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, Dg = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, Ug = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, Ng = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, Gg = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Tg = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Ig = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, wg = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Qg = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Lg = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, Wg = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, Vg = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, Hg = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, $g = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, zg = {
  opened: "opened",
  failed: "failed",
  succeeded: "succeeded"
}, Yg = {
  payment_method_not_refundable: "payment_method_not_refundable",
  bank_return: "bank_return"
}, Xg = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, jg = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, Zg = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, Jg = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, tq = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, eq = {
  s3_datashare_upload: "s3_datashare_upload",
  manual_datashare_upload: "manual_datashare_upload",
  missing_sales_datashare: "missing_sales_datashare",
  missing_bank_account_datashare: "missing_bank_account_datashare",
  api_errors: "api_errors",
  crm_errors: "crm_errors",
  webhook_errors: "webhook_errors",
  user_added: "user_added",
  send_otp: "send_otp"
}, nq = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, rq = {
  opened: "opened",
  accepted: "accepted",
  canceled: "canceled",
  declined: "declined",
  finalized: "finalized"
}, sq = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, oq = {
  void_cash_advance: "void_cash_advance"
}, aq = {
  basic_offer_generation: "basic_offer_generation",
  underwriting: "underwriting",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  introductory_offer_preview: "introductory_offer_preview",
  introductory_offer_underwriting: "introductory_offer_underwriting",
  introductory_offer_underwriting_rate_limit_exceeded: "introductory_offer_underwriting_rate_limit_exceeded",
  offer_acceptance: "offer_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification"
}, uq = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  capital: "capital",
  merchant_bank: "merchant_bank"
}, iq = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  start_application: "start_application",
  offer_preview: "offer_preview"
}, cq = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, yq = {
  enter_gmv: "enter_gmv",
  upload_csv: "upload_csv",
  generate_offers: "generate_offers",
  awaiting_review: "awaiting_review",
  map_emails: "map_emails",
  email_campaign: "email_campaign",
  finished: "finished"
}, dq = {
  active: "active",
  inactive: "inactive"
}, pq = {
  minimum: "minimum",
  sales_based: "sales_based"
}, _q = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, mq = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, lq = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment",
  card_dispute_credit: "card_dispute_credit"
}, gq = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, qq = {
  money_in: "money_in",
  money_out: "money_out"
}, fq = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, Oq = {
  manual: "manual",
  payout: "payout"
}, hq = {
  electrical: "electrical",
  wire: "wire"
}, Kq = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, Pq = {
  credit_limit: "credit_limit"
}, bq = {
  checking: "checking",
  savings: "savings"
}, xq = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, kq = {
  physical: "physical",
  virtual: "virtual"
}, Mq = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Fq = {
  cash: "cash",
  credit: "credit"
}, Rq = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, Bq = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, Cq = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, Eq = {
  checking: "checking",
  savings: "savings"
}, vq = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, Sq = {
  pre_launch_email: "pre_launch_email",
  capital_product_offer_email: "capital_product_offer_email"
}, Aq = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, Dq = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, Uq = {
  ucc: "ucc",
  federal: "federal",
  state: "state",
  judgment: "judgment"
}, Nq = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, Gq = {
  pending_user: "pending_user",
  user_reviewed: "user_reviewed",
  do_not_need_user_review: "do_not_need_user_review"
}, Tq = {
  date_of_birth: "date_of_birth",
  address: "address",
  name: "name",
  tin: "tin"
}, Iq = {
  address: "address",
  name: "name",
  tin: "tin"
}, wq = {
  incomplete: "incomplete",
  approved: "approved",
  denied: "denied",
  under_review: "under_review",
  pending: "pending"
}, Qq = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, Lq = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, Wq = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, Vq = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, Hq = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, $q = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, zq = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, Yq = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, Xq = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, jq = {
  manual: "manual",
  rutter: "rutter"
}, Zq = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean",
  long: "long"
}, Jq = {
  not_started: "not_started",
  under_review: "under_review",
  approved: "approved"
}, tf = {
  business: "business",
  account: "account"
}, ef = {
  system: "system",
  user: "user"
}, nf = {
  active: "active",
  inactive: "inactive"
}, rf = {
  not_started: "not_started",
  completed: "completed",
  in_progress: "in_progress",
  failed: "failed"
}, sf = {
  partner_datashare: "partner_datashare",
  manual_edit: "manual_edit",
  kyc: "kyc",
  auto_opt_in: "auto_opt_in",
  auto_opt_in_backfill: "auto_opt_in_backfill"
}, of = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, af = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record",
  intro_offer: "intro_offer"
}, uf = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, cf = {
  partner_wide_program: "partner_wide_program",
  business_level_program: "business_level_program"
}, yf = {
  under_review: "under_review",
  scheduled: "scheduled",
  running: "running",
  ended: "ended",
  denied: "denied"
}, df = {
  revenue_share: "revenue_share",
  parafin_funded: "parafin_funded"
}, pf = {
  apply: "apply",
  lift: "lift"
}, _f = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location"
}, mf = {
  pending: "pending",
  under_review: "under_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  error: "error"
}, lf = {
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday"
}, gf = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  stripe_ingestion: "stripe_ingestion",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, qf = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, ff = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, Of = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, hf = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BOV: "BOV",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  COU: "COU",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MXV: "MXV",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLE: "SLE",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  USN: "USN",
  UYI: "UYI",
  UYU: "UYU",
  UYW: "UYW",
  UZS: "UZS",
  VED: "VED",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XAG: "XAG",
  XAU: "XAU",
  XBA: "XBA",
  XBB: "XBB",
  XBC: "XBC",
  XBD: "XBD",
  XCD: "XCD",
  XDR: "XDR",
  XOF: "XOF",
  XPD: "XPD",
  XPF: "XPF",
  XPT: "XPT",
  XSU: "XSU",
  XTS: "XTS",
  XUA: "XUA",
  XXX: "XXX",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL"
}, Kf = {
  USD: "USD",
  CAD: "CAD"
}, Pf = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, bf = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, xf = {
  offer_summary: "offer_summary"
}, kf = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, Mf = {
  US: "US",
  CA: "CA"
}, Ff = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Rf = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Bf = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Cf = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Ef = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, vf = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, Sf = {
  chargeback: "chargeback",
  normal: "normal"
}, Af = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, Df = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Uf = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Nf = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Gf = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Tf = {
  physical: "physical",
  virtual: "virtual"
}, If = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, wf = {
  physical: "physical",
  virtual: "virtual"
}, Qf = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Lf = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Wf = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Vf = {
  seen_accounting_integrations: "seen_accounting_integrations"
}, Hf = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, $f = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, zf = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, Yf = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, Xf = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, jf = {
  application_created: "application_created",
  application_progress: "application_progress",
  manual_review: "manual_review",
  denied: "denied",
  multiple_manual_review: "multiple_manual_review",
  underwriting_manual_review: "underwriting_manual_review",
  underwriting_denial: "underwriting_denial",
  multiple_denied_reasons: "multiple_denied_reasons",
  kyc_manual_review: "kyc_manual_review",
  kyc_denial: "kyc_denial",
  bank_statement_upload_manual_review: "bank_statement_upload_manual_review",
  plaid_investigation_manual_review: "plaid_investigation_manual_review",
  funding_check_manual_review: "funding_check_manual_review",
  funding_check_denial: "funding_check_denial",
  auto_approval: "auto_approval",
  manual_review_approval: "manual_review_approval",
  manual_review_denial: "manual_review_denial",
  offer_refreshed: "offer_refreshed",
  offer_accepted: "offer_accepted",
  funding_completed: "funding_completed",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_pulled: "offer_pulled",
  consistency: "consistency",
  unsupported: "unsupported",
  metadata_update: "metadata_update"
}, Zf = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, Jf = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, tO = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, eO = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl",
  merchant_bank: "merchant_bank"
}, nO = {
  new: "new",
  verified: "verified"
}, rO = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, sO = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  problematic_payment_history_with_others: "problematic_payment_history_with_others",
  collection_action_or_judgement: "collection_action_or_judgement",
  la_wildfire_jan2025: "la_wildfire_jan2025",
  temporary_program_termination: "temporary_program_termination",
  "": ""
}, oO = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, aO = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, f = (t, e) => a.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), O = (t) => ["/business_cores", ...t ? [t] : []], h = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? O(t), queryFn: ({ signal: u }) => f(t, { signal: u, ...r }), ...n };
};
function uO(t, e) {
  const n = h(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const K = (t, e) => a.post(
  "/business_cores",
  t,
  e
), P = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return K(o, n);
  }, ...e };
}, iO = (t) => {
  const e = P(t);
  return i(e);
}, b = (t, e) => a.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), x = (t) => ["/business_annotations", ...t ? [t] : []], k = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? x(t), queryFn: ({ signal: u }) => b(t, { signal: u, ...r }), ...n };
};
function cO(t, e) {
  const n = k(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const M = (t, e) => a.post(
  "/business_annotations",
  t,
  e
), F = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return M(o, n);
  }, ...e };
}, yO = (t) => {
  const e = F(t);
  return i(e);
}, R = (t, e) => a.get(
  "/cash_advance_charge_offs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), B = (t) => ["/cash_advance_charge_offs", ...t ? [t] : []], C = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? B(t), queryFn: ({ signal: u }) => R(t, { signal: u, ...r }), ...n };
};
function dO(t, e) {
  const n = C(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const E = (t, e) => a.post(
  "/cash_advance_charge_offs",
  t,
  e
), v = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return E(o, n);
  }, ...e };
}, pO = (t) => {
  const e = v(t);
  return i(e);
}, S = (t, e) => a.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), A = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], D = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? A(t), queryFn: ({ signal: u }) => S(t, { signal: u, ...r }), ...n };
};
function _O(t, e) {
  const n = D(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const U = (t, e) => a.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), N = (t) => ["/cash_advance_pauses", ...t ? [t] : []], G = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? N(t), queryFn: ({ signal: u }) => U(t, { signal: u, ...r }), ...n };
};
function mO(t, e) {
  const n = G(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const T = (t, e) => a.post(
  "/cash_advance_pauses",
  t,
  e
), I = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return T(o, n);
  }, ...e };
}, lO = (t) => {
  const e = I(t);
  return i(e);
}, w = (t, e) => a.delete(
  `/cash_advance_pauses/${t}`,
  e
), Q = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: o } = s ?? {};
    return w(o, n);
  }, ...e };
}, gO = (t) => {
  const e = Q(t);
  return i(e);
}, L = (t, e, n) => a.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), W = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: o, data: u } = s ?? {};
    return L(o, u, n);
  }, ...e };
}, qO = (t) => {
  const e = W(t);
  return i(e);
}, V = (t, e) => a.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), H = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], $ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? H(t), queryFn: ({ signal: u }) => V(t, { signal: u, ...r }), ...n };
};
function fO(t, e) {
  const n = $(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const z = (t, e) => a.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Y = (t) => ["/future_activities", ...t ? [t] : []], X = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Y(t), queryFn: ({ signal: u }) => z(t, { signal: u, ...r }), ...n };
};
function OO(t, e) {
  const n = X(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const j = (t, e) => a.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Z = (t) => ["/partners", ...t ? [t] : []], J = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Z(t), queryFn: ({ signal: u }) => j(t, { signal: u, ...r }), ...n };
};
function hO(t, e) {
  const n = J(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tt = (t, e) => a.post(
  "/partners",
  t,
  e
), et = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return tt(o, n);
  }, ...e };
}, KO = (t) => {
  const e = et(t);
  return i(e);
}, nt = (t) => a.get(
  "/partners/underwriting",
  t
), rt = () => ["/partners/underwriting"], st = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? rt(), queryFn: ({ signal: o }) => nt({ signal: o, ...n }), ...e };
};
function PO(t) {
  const e = st(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ot = (t, e, n) => a.patch(
  `/partners/${t}`,
  e,
  n
), at = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ot(o, u, n);
  }, ...e };
}, bO = (t) => {
  const e = at(t);
  return i(e);
}, ut = (t, e) => a.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), it = (t) => ["/partner/api_keys", ...t ? [t] : []], ct = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? it(t), queryFn: ({ signal: u }) => ut(t, { signal: u, ...r }), ...n };
};
function xO(t, e) {
  const n = ct(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yt = (t, e) => a.post(
  "/partner/api_keys",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return yt(o, n);
  }, ...e };
}, kO = (t) => {
  const e = dt(t);
  return i(e);
}, pt = (t) => a.get(
  "/partner/organizations",
  t
), _t = () => ["/partner/organizations"], mt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? _t(), queryFn: ({ signal: o }) => pt({ signal: o, ...n }), ...e };
};
function MO(t) {
  const e = mt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const lt = (t, e) => a.post(
  "/partner/organizations",
  t,
  e
), gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return lt(o, n);
  }, ...e };
}, FO = (t) => {
  const e = gt(t);
  return i(e);
}, qt = (t, e) => a.post(
  "/partner/organizations/link_partner",
  t,
  e
), ft = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return qt(o, n);
  }, ...e };
}, RO = (t) => {
  const e = ft(t);
  return i(e);
}, Ot = (t, e) => a.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ht = (t) => ["/partner/organizations/partners", ...t ? [t] : []], Kt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ht(t), queryFn: ({ signal: u }) => Ot(t, { signal: u, ...r }), ...n };
};
function BO(t, e) {
  const n = Kt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pt = (t, e) => a.post(
  "/slack/authorize",
  t,
  e
), bt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Pt(o, n);
  }, ...e };
}, CO = (t) => {
  const e = bt(t);
  return i(e);
}, xt = (t) => a.get(
  "/slack/channels",
  t
), kt = () => ["/slack/channels"], Mt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? kt(), queryFn: ({ signal: o }) => xt({ signal: o, ...n }), ...e };
};
function EO(t) {
  const e = Mt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ft = (t, e) => a.delete(
  `/slack/channels/${t}`,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Ft(o, n);
  }, ...e };
}, vO = (t) => {
  const e = Rt(t);
  return i(e);
}, Bt = (t) => a.get(
  "/partner/users",
  t
), Ct = () => ["/partner/users"], Et = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ct(), queryFn: ({ signal: o }) => Bt({ signal: o, ...n }), ...e };
};
function SO(t) {
  const e = Et(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const vt = (t, e) => a.post(
  "/partner/users",
  t,
  e
), St = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vt(o, n);
  }, ...e };
}, AO = (t) => {
  const e = St(t);
  return i(e);
}, At = (t, e) => a.patch(
  "/partner/users",
  t,
  e
), Dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return At(o, n);
  }, ...e };
}, DO = (t) => {
  const e = Dt(t);
  return i(e);
}, Ut = (t, e) => a.delete(
  "/partner/delete_users",
  { data: t, ...e }
), Nt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ut(o, n);
  }, ...e };
}, UO = (t) => {
  const e = Nt(t);
  return i(e);
}, Gt = (t, e) => a.delete(
  "/organization/delete_users",
  { data: t, ...e }
), Tt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gt(o, n);
  }, ...e };
}, NO = (t) => {
  const e = Tt(t);
  return i(e);
}, It = (t, e) => a.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wt = (t) => ["/partner/public_configs", ...t ? [t] : []], Qt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wt(t), queryFn: ({ signal: u }) => It(t, { signal: u, ...r }), ...n };
};
function GO(t, e) {
  const n = Qt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Lt = (t, e) => a.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wt = (t) => ["/partner/configs", ...t ? [t] : []], Vt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wt(t), queryFn: ({ signal: u }) => Lt(t, { signal: u, ...r }), ...n };
};
function TO(t, e) {
  const n = Vt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ht = (t, e, n) => a.patch(
  `/partner/configs/${t}`,
  e,
  n
), $t = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Ht(o, u, n);
  }, ...e };
}, IO = (t) => {
  const e = $t(t);
  return i(e);
}, zt = (t) => a.get(
  "/partner/sync_webhooks",
  t
), Yt = () => ["/partner/sync_webhooks"], Xt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Yt(), queryFn: ({ signal: o }) => zt({ signal: o, ...n }), ...e };
};
function wO(t) {
  const e = Xt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const jt = (t, e) => a.post(
  "/partner/sync_webhooks",
  t,
  e
), Zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return jt(o, n);
  }, ...e };
}, QO = (t) => {
  const e = Zt(t);
  return i(e);
}, Jt = (t, e) => a.delete(
  `/partner/sync_webhooks/${t}`,
  e
), te = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Jt(o, n);
  }, ...e };
}, LO = (t) => {
  const e = te(t);
  return i(e);
}, ee = (t, e, n) => a.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), ne = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ee(o, u, n);
  }, ...e };
}, WO = (t) => {
  const e = ne(t);
  return i(e);
}, re = (t, e) => a.post(
  "/auth/tokens",
  t,
  e
), se = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return re(o, n);
  }, ...e };
}, VO = (t) => {
  const e = se(t);
  return i(e);
}, oe = (t, e) => a.post(
  "/hackathon/partners",
  t,
  e
), ae = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return oe(o, n);
  }, ...e };
}, HO = (t) => {
  const e = ae(t);
  return i(e);
}, ue = (t) => a.post(
  "/hackathon/create_pilot",
  void 0,
  t
), ie = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ue(n), ...e };
}, $O = (t) => {
  const e = ie(t);
  return i(e);
}, ce = (t) => a.get(
  "/hackathon/get_pilot",
  t
), ye = () => ["/hackathon/get_pilot"], de = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ye(), queryFn: ({ signal: o }) => ce({ signal: o, ...n }), ...e };
};
function zO(t) {
  const e = de(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const pe = (t) => a.post(
  "/hackathon/generate_offers",
  void 0,
  t
), _e = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => pe(n), ...e };
}, YO = (t) => {
  const e = _e(t);
  return i(e);
}, me = (t) => a.post(
  "/hackathon/await_parafin_representative",
  void 0,
  t
), le = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => me(n), ...e };
}, XO = (t) => {
  const e = le(t);
  return i(e);
}, ge = (t) => a.post(
  "/hackathon/send_offers",
  void 0,
  t
), qe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ge(n), ...e };
}, jO = (t) => {
  const e = qe(t);
  return i(e);
}, fe = (t, e) => a.post(
  "/hackathon/gmv",
  t,
  e
), Oe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return fe(o, n);
  }, ...e };
}, ZO = (t) => {
  const e = Oe(t);
  return i(e);
}, he = (t) => a.post(
  "/hackathon/reset",
  void 0,
  t
), Ke = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => he(n), ...e };
}, JO = (t) => {
  const e = Ke(t);
  return i(e);
}, Pe = (t) => a.post(
  "/hackathon/map_offers_to_emails",
  void 0,
  t
), be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Pe(n), ...e };
}, th = (t) => {
  const e = be(t);
  return i(e);
}, xe = (t) => a.post(
  "/hackathon/upload_sales_csv",
  void 0,
  t
), ke = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => xe(n), ...e };
}, eh = (t) => {
  const e = ke(t);
  return i(e);
}, Me = (t, e) => a.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fe = (t) => ["/statements_v2", ...t ? [t] : []], Re = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fe(t), queryFn: ({ signal: u }) => Me(t, { signal: u, ...r }), ...n };
};
function nh(t, e) {
  const n = Re(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Be = (t, e) => a.post(
  "/marketing/review",
  t,
  e
), Ce = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Be(o, n);
  }, ...e };
}, rh = (t) => {
  const e = Ce(t);
  return i(e);
}, Ee = (t) => a.get(
  "/marketing/integrations",
  t
), ve = () => ["/marketing/integrations"], Se = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ve(), queryFn: ({ signal: o }) => Ee({ signal: o, ...n }), ...e };
};
function sh(t) {
  const e = Se(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ae = (t, e) => a.post(
  "/marketing/integrations",
  t,
  e
), De = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ae(o, n);
  }, ...e };
}, oh = (t) => {
  const e = De(t);
  return i(e);
}, Ue = (t, e) => a.delete(
  `/marketing/integrations/${t}`,
  e
), Ne = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: o } = s ?? {};
    return Ue(o, n);
  }, ...e };
}, ah = (t) => {
  const e = Ne(t);
  return i(e);
}, Ge = (t) => a.get(
  "/marketing/gtm_toolkit",
  t
), Te = () => ["/marketing/gtm_toolkit"], Ie = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Te(), queryFn: ({ signal: o }) => Ge({ signal: o, ...n }), ...e };
};
function uh(t) {
  const e = Ie(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const we = (t) => a.get(
  "/marketing/paragon_token",
  t
), Qe = () => ["/marketing/paragon_token"], Le = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Qe(), queryFn: ({ signal: o }) => we({ signal: o, ...n }), ...e };
};
function ih(t) {
  const e = Le(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const We = (t, e) => a.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ve = (t) => ["/beneficial_owners", ...t ? [t] : []], He = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ve(t), queryFn: ({ signal: u }) => We(t, { signal: u, ...r }), ...n };
};
function ch(t, e) {
  const n = He(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $e = (t, e) => a.post(
  "/beneficial_owners",
  t,
  e
), ze = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $e(o, n);
  }, ...e };
}, yh = (t) => {
  const e = ze(t);
  return i(e);
}, Ye = (t, e) => a.delete(
  `/beneficial_owners/${t}`,
  e
), Xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Ye(o, n);
  }, ...e };
}, dh = (t) => {
  const e = Xe(t);
  return i(e);
}, je = (t, e, n) => a.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), Ze = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return je(o, u, n);
  }, ...e };
}, ph = (t) => {
  const e = Ze(t);
  return i(e);
}, Je = (t, e) => a.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tn = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], en = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tn(t), queryFn: ({ signal: u }) => Je(t, { signal: u, ...r }), ...n };
};
function _h(t, e) {
  const n = en(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const nn = (t, e) => a.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), rn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return nn(o, n);
  }, ...e };
}, mh = (t) => {
  const e = rn(t);
  return i(e);
}, sn = (t, e) => a.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), on = (t) => ["/banks", ...t ? [t] : []], an = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? on(t), queryFn: ({ signal: u }) => sn(t, { signal: u, ...r }), ...n };
};
function lh(t, e) {
  const n = an(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const un = (t, e) => a.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cn = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], yn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cn(t), queryFn: ({ signal: u }) => un(t, { signal: u, ...r }), ...n };
};
function gh(t, e) {
  const n = yn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const dn = (t, e) => a.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pn = (t) => ["/business_table_rows/mca", ...t ? [t] : []], _n = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pn(t), queryFn: ({ signal: u }) => dn(t, { signal: u, ...r }), ...n };
};
function qh(t, e) {
  const n = _n(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const mn = (t, e) => a.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ln = (t) => ["/business_table_rows/loan", ...t ? [t] : []], gn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ln(t), queryFn: ({ signal: u }) => mn(t, { signal: u, ...r }), ...n };
};
function fh(t, e) {
  const n = gn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qn = (t, e) => a.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fn = (t) => ["/business_table_rows/card", ...t ? [t] : []], On = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fn(t), queryFn: ({ signal: u }) => qn(t, { signal: u, ...r }), ...n };
};
function Oh(t, e) {
  const n = On(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const hn = (t, e) => a.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kn = (t) => ["/business/officers", ...t ? [t] : []], Pn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kn(t), queryFn: ({ signal: u }) => hn(t, { signal: u, ...r }), ...n };
};
function hh(t, e) {
  const n = Pn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const bn = (t, e) => a.post(
  "/business/officers",
  t,
  e
), xn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bn(o, n);
  }, ...e };
}, Kh = (t) => {
  const e = xn(t);
  return i(e);
}, kn = (t, e) => a.delete(
  `/business/officers/${t}`,
  e
), Mn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return kn(o, n);
  }, ...e };
}, Ph = (t) => {
  const e = Mn(t);
  return i(e);
}, Fn = (t, e, n) => a.patch(
  `/business/officers/${t}`,
  e,
  n
), Rn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Fn(o, u, n);
  }, ...e };
}, bh = (t) => {
  const e = Rn(t);
  return i(e);
}, Bn = (t) => a.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), Cn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Bn(n), ...e };
}, xh = (t) => {
  const e = Cn(t);
  return i(e);
}, En = (t, e) => a.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vn = (t) => ["/dataset_uploads", ...t ? [t] : []], Sn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vn(t), queryFn: ({ signal: u }) => En(t, { signal: u, ...r }), ...n };
};
function kh(t, e) {
  const n = Sn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => a.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], Un = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dn(t), queryFn: ({ signal: u }) => An(t, { signal: u, ...r }), ...n };
};
function Mh(t, e) {
  const n = Un(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Nn = (t, e) => a.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gn = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], Tn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gn(t), queryFn: ({ signal: u }) => Nn(t, { signal: u, ...r }), ...n };
};
function Fh(t, e) {
  const n = Tn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const In = (t, e) => a.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wn = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], Qn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wn(t), queryFn: ({ signal: u }) => In(t, { signal: u, ...r }), ...n };
};
function Rh(t, e) {
  const n = Qn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ln = (t, e) => a.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wn = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], Vn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wn(t), queryFn: ({ signal: u }) => Ln(t, { signal: u, ...r }), ...n };
};
function Bh(t, e) {
  const n = Vn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hn = (t, e) => a.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), $n = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Hn(o, n);
  }, ...e };
}, Ch = (t) => {
  const e = $n(t);
  return i(e);
}, zn = (t, e) => a.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yn = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], Xn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yn(t), queryFn: ({ signal: u }) => zn(t, { signal: u, ...r }), ...n };
};
function Eh(t, e) {
  const n = Xn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jn = (t, e) => a.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), Zn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return jn(o, n);
  }, ...e };
}, vh = (t) => {
  const e = Zn(t);
  return i(e);
}, Jn = (t, e) => a.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tr = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], er = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tr(t), queryFn: ({ signal: u }) => Jn(t, { signal: u, ...r }), ...n };
};
function Sh(t, e) {
  const n = er(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const nr = (t, e) => a.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), rr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return nr(o, n);
  }, ...e };
}, Ah = (t) => {
  const e = rr(t);
  return i(e);
}, sr = (t, e) => a.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), or = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return sr(o, n);
  }, ...e };
}, Dh = (t) => {
  const e = or(t);
  return i(e);
}, ar = (t, e) => a.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ur = (t) => ["/dataingestion/schema", ...t ? [t] : []], ir = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ur(t), queryFn: ({ signal: u }) => ar(t, { signal: u, ...r }), ...n };
};
function Uh(t, e) {
  const n = ir(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const cr = (t, e) => a.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yr = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], dr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yr(t), queryFn: ({ signal: u }) => cr(t, { signal: u, ...r }), ...n };
};
function Nh(t, e) {
  const n = dr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const pr = (t, e) => a.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), _r = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pr(o, n);
  }, ...e };
}, Gh = (t) => {
  const e = _r(t);
  return i(e);
}, mr = (t, e) => a.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), lr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return mr(o, n);
  }, ...e };
}, Th = (t) => {
  const e = lr(t);
  return i(e);
}, gr = (t, e) => a.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qr = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], fr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qr(t), queryFn: ({ signal: u }) => gr(t, { signal: u, ...r }), ...n };
};
function Ih(t, e) {
  const n = fr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Or = (t, e) => a.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hr = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], Kr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hr(t), queryFn: ({ signal: u }) => Or(t, { signal: u, ...r }), ...n };
};
function wh(t, e) {
  const n = Kr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pr = (t, e) => a.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), br = (t) => ["/capital_products", ...t ? [t] : []], xr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? br(t), queryFn: ({ signal: u }) => Pr(t, { signal: u, ...r }), ...n };
};
function Qh(t, e) {
  const n = xr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const kr = (t, e) => a.post(
  "/capital_products",
  t,
  e
), Mr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return kr(o, n);
  }, ...e };
}, Lh = (t) => {
  const e = Mr(t);
  return i(e);
}, Fr = (t, e, n) => a.patch(
  `/capital_products/${t}`,
  e,
  n
), Rr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Fr(o, u, n);
  }, ...e };
}, Wh = (t) => {
  const e = Rr(t);
  return i(e);
}, Br = (t, e) => a.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cr = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], Er = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cr(t), queryFn: ({ signal: u }) => Br(t, { signal: u, ...r }), ...n };
};
function Vh(t, e) {
  const n = Er(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vr = (t, e) => a.post(
  "/capital_product_offer_collections",
  t,
  e
), Sr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vr(o, n);
  }, ...e };
}, Hh = (t) => {
  const e = Sr(t);
  return i(e);
}, Ar = (t, e) => a.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dr = (t) => ["/capital_product_activities", ...t ? [t] : []], Ur = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dr(t), queryFn: ({ signal: u }) => Ar(t, { signal: u, ...r }), ...n };
};
function $h(t, e) {
  const n = Ur(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Nr = (t, e) => a.get(
  "/capital_product_applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gr = (t) => ["/capital_product_applications", ...t ? [t] : []], Tr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gr(t), queryFn: ({ signal: u }) => Nr(t, { signal: u, ...r }), ...n };
};
function zh(t, e) {
  const n = Tr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ir = (t, e) => a.post(
  "/capital_product_applications",
  t,
  e
), wr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ir(o, n);
  }, ...e };
}, Yh = (t) => {
  const e = wr(t);
  return i(e);
}, Qr = (t, e) => a.get(
  "/capital_product_application_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lr = (t) => ["/capital_product_application_snapshots", ...t ? [t] : []], Wr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lr(t), queryFn: ({ signal: u }) => Qr(t, { signal: u, ...r }), ...n };
};
function Xh(t, e) {
  const n = Wr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vr = (t, e) => a.post(
  "/net_top_up",
  t,
  e
), Hr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Vr(o, n);
  }, ...e };
}, jh = (t) => {
  const e = Hr(t);
  return i(e);
}, $r = (t, e) => a.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zr = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], Yr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zr(t), queryFn: ({ signal: u }) => $r(t, { signal: u, ...r }), ...n };
};
function Zh(t, e) {
  const n = Yr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Xr = (t, e) => a.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), jr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], Zr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? jr(t), queryFn: ({ signal: u }) => Xr(t, { signal: u, ...r }), ...n };
};
function Jh(t, e) {
  const n = Zr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Jr = (t) => a.get(
  "/benchmark_capital_products_ownerships",
  t
), ts = () => ["/benchmark_capital_products_ownerships"], es = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ts(), queryFn: ({ signal: o }) => Jr({ signal: o, ...n }), ...e };
};
function tK(t) {
  const e = es(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ns = (t, e) => a.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rs = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], ss = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rs(t), queryFn: ({ signal: u }) => ns(t, { signal: u, ...r }), ...n };
};
function eK(t, e) {
  const n = ss(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const os = (t, e) => a.post(
  "/offered_capital_product_discount",
  t,
  e
), as = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return os(o, n);
  }, ...e };
}, nK = (t) => {
  const e = as(t);
  return i(e);
}, us = (t, e) => a.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), is = (t) => ["/capital_product_intents", ...t ? [t] : []], cs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? is(t), queryFn: ({ signal: u }) => us(t, { signal: u, ...r }), ...n };
};
function rK(t, e) {
  const n = cs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ys = (t, e) => a.post(
  "/capital_product_intents",
  t,
  e
), ds = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ys(o, n);
  }, ...e };
}, sK = (t) => {
  const e = ds(t);
  return i(e);
}, ps = (t, e, n) => a.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), _s = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ps(o, u, n);
  }, ...e };
}, oK = (t) => {
  const e = _s(t);
  return i(e);
}, ms = (t, e) => a.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ls = (t) => ["/counteroffer", ...t ? [t] : []], gs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ls(t), queryFn: ({ signal: u }) => ms(t, { signal: u, ...r }), ...n };
};
function aK(t, e) {
  const n = gs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qs = (t, e) => a.post(
  "/capital_product_super_applications",
  t,
  e
), fs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return qs(o, n);
  }, ...e };
}, uK = (t) => {
  const e = fs(t);
  return i(e);
}, Os = (t, e) => a.post(
  "/capital_product_super_application_actions",
  t,
  e
), hs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Os(o, n);
  }, ...e };
}, iK = (t) => {
  const e = hs(t);
  return i(e);
}, Ks = (t, e, n) => a.get(
  `/capital_product_offer_chunk_calculated/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ps = (t, e) => [`/capital_product_offer_chunk_calculated/${t}`, ...e ? [e] : []], bs = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ps(t, e), queryFn: ({ signal: y }) => Ks(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function cK(t, e, n) {
  const r = bs(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const xs = (t, e) => a.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ks = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], Ms = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ks(t), queryFn: ({ signal: u }) => xs(t, { signal: u, ...r }), ...n };
};
function yK(t, e) {
  const n = Ms(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fs = (t, e) => a.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), Rs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Fs(o, n);
  }, ...e };
}, dK = (t) => {
  const e = Rs(t);
  return i(e);
}, Bs = (t, e) => a.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cs = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], Es = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cs(t), queryFn: ({ signal: u }) => Bs(t, { signal: u, ...r }), ...n };
};
function pK(t, e) {
  const n = Es(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vs = (t, e, n) => a.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), Ss = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return vs(o, u, n);
  }, ...e };
}, _K = (t) => {
  const e = Ss(t);
  return i(e);
}, As = (t, e) => a.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ds = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], Us = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ds(t), queryFn: ({ signal: u }) => As(t, { signal: u, ...r }), ...n };
};
function mK(t, e) {
  const n = Us(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ns = (t, e) => a.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gs = (t) => ["/funding/bank_account_info", ...t ? [t] : []], Ts = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gs(t), queryFn: ({ signal: u }) => Ns(t, { signal: u, ...r }), ...n };
};
function lK(t, e) {
  const n = Ts(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Is = (t, e) => a.post(
  "/funding/bank_account_info",
  t,
  e
), ws = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Is(o, n);
  }, ...e };
}, gK = (t) => {
  const e = ws(t);
  return i(e);
}, Qs = (t, e, n) => a.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), Ls = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Qs(o, u, n);
  }, ...e };
}, qK = (t) => {
  const e = Ls(t);
  return i(e);
}, Ws = (t, e) => a.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vs = (t) => ["/funding/accounts", ...t ? [t] : []], Hs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vs(t), queryFn: ({ signal: u }) => Ws(t, { signal: u, ...r }), ...n };
};
function fK(t, e) {
  const n = Hs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $s = (t, e) => a.post(
  "/funding/accounts",
  t,
  e
), zs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $s(o, n);
  }, ...e };
}, OK = (t) => {
  const e = zs(t);
  return i(e);
}, Ys = (t, e) => a.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xs = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], js = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xs(t), queryFn: ({ signal: u }) => Ys(t, { signal: u, ...r }), ...n };
};
function hK(t, e) {
  const n = js(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zs = (t, e) => a.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), Js = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Zs(o, n);
  }, ...e };
}, KK = (t) => {
  const e = Js(t);
  return i(e);
}, to = (t, e, n) => a.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), eo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return to(o, u, n);
  }, ...e };
}, PK = (t) => {
  const e = eo(t);
  return i(e);
}, no = (t, e) => a.get(
  "/kyc_retry_fields",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ro = (t) => ["/kyc_retry_fields", ...t ? [t] : []], so = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ro(t), queryFn: ({ signal: u }) => no(t, { signal: u, ...r }), ...n };
};
function bK(t, e) {
  const n = so(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oo = (t, e) => a.post(
  "/kyc_retry",
  t,
  e
), ao = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return oo(o, n);
  }, ...e };
}, xK = (t) => {
  const e = ao(t);
  return i(e);
}, uo = (t, e) => a.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), io = (t) => ["/onboarding/states", ...t ? [t] : []], co = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? io(t), queryFn: ({ signal: u }) => uo(t, { signal: u, ...r }), ...n };
};
function kK(t, e) {
  const n = co(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yo = (t, e) => a.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), po = (t) => ["/onboarding/info_needed", ...t ? [t] : []], _o = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? po(t), queryFn: ({ signal: u }) => yo(t, { signal: u, ...r }), ...n };
};
function MK(t, e) {
  const n = _o(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const mo = (t, e) => a.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lo = (t) => ["/business_identities", ...t ? [t] : []], go = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lo(t), queryFn: ({ signal: u }) => mo(t, { signal: u, ...r }), ...n };
};
function FK(t, e) {
  const n = go(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qo = (t, e, n) => a.patch(
  `/business_identities/${t}`,
  e,
  n
), fo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return qo(o, u, n);
  }, ...e };
}, RK = (t) => {
  const e = fo(t);
  return i(e);
}, Oo = (t, e) => a.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ho = (t) => ["/owners", ...t ? [t] : []], Ko = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ho(t), queryFn: ({ signal: u }) => Oo(t, { signal: u, ...r }), ...n };
};
function BK(t, e) {
  const n = Ko(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Po = (t, e, n) => a.patch(
  `/owners/${t}`,
  e,
  n
), bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Po(o, u, n);
  }, ...e };
}, CK = (t) => {
  const e = bo(t);
  return i(e);
}, xo = (t, e) => a.post(
  "/business",
  t,
  e
), ko = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xo(o, n);
  }, ...e };
}, EK = (t) => {
  const e = ko(t);
  return i(e);
}, Mo = (t, e) => a.post(
  "/merchant_bank/business",
  t,
  e
), Fo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Mo(o, n);
  }, ...e };
}, vK = (t) => {
  const e = Fo(t);
  return i(e);
}, Ro = (t) => a.post(
  "/amazon_redirect_url",
  void 0,
  t
), Bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Ro(n), ...e };
}, SK = (t) => {
  const e = Bo(t);
  return i(e);
}, Co = (t, e) => a.post(
  "/spd",
  t,
  e
), Eo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Co(o, n);
  }, ...e };
}, AK = (t) => {
  const e = Eo(t);
  return i(e);
}, vo = (t, e) => a.post(
  "/capital_product_business",
  t,
  e
), So = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vo(o, n);
  }, ...e };
}, DK = (t) => {
  const e = So(t);
  return i(e);
}, Ao = (t, e) => a.patch(
  "/flex_loan_age",
  t,
  e
), Do = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ao(o, n);
  }, ...e };
}, UK = (t) => {
  const e = Do(t);
  return i(e);
}, Uo = (t, e) => a.patch(
  "/flex_loan_ownership",
  t,
  e
), No = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Uo(o, n);
  }, ...e };
}, NK = (t) => {
  const e = No(t);
  return i(e);
}, Go = (t, e) => a.patch(
  "/flex_loan_fund",
  t,
  e
), To = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Go(o, n);
  }, ...e };
}, GK = (t) => {
  const e = To(t);
  return i(e);
}, Io = (t, e) => a.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: o } = s ?? {};
    return Io(o, n);
  }, ...e };
}, TK = (t) => {
  const e = wo(t);
  return i(e);
}, Qo = (t, e) => a.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lo = (t) => ["/cards", ...t ? [t] : []], Wo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lo(t), queryFn: ({ signal: u }) => Qo(t, { signal: u, ...r }), ...n };
};
function IK(t, e) {
  const n = Wo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vo = (t, e) => a.post(
  "/cards",
  t,
  e
), Ho = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Vo(o, n);
  }, ...e };
}, wK = (t) => {
  const e = Ho(t);
  return i(e);
}, $o = (t, e) => a.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zo = (t) => ["/businesses/products", ...t ? [t] : []], Yo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zo(t), queryFn: ({ signal: u }) => $o(t, { signal: u, ...r }), ...n };
};
function QK(t, e) {
  const n = Yo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Xo = (t, e) => a.post(
  "/business/auth_link",
  t,
  e
), jo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Xo(o, n);
  }, ...e };
}, LK = (t) => {
  const e = jo(t);
  return i(e);
}, Zo = (t, e) => a.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jo = (t) => ["/document_upload/group", ...t ? [t] : []], ta = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jo(t), queryFn: ({ signal: u }) => Zo(t, { signal: u, ...r }), ...n };
};
function WK(t, e) {
  const n = ta(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ea = (t, e) => a.post(
  "/document_upload/group",
  t,
  e
), na = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ea(o, n);
  }, ...e };
}, VK = (t) => {
  const e = na(t);
  return i(e);
}, ra = (t, e) => a.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sa = (t) => ["/document_upload/list", ...t ? [t] : []], oa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sa(t), queryFn: ({ signal: u }) => ra(t, { signal: u, ...r }), ...n };
};
function HK(t, e) {
  const n = oa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const aa = (t, e) => a.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ua = (t) => ["/restriction", ...t ? [t] : []], ia = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ua(t), queryFn: ({ signal: u }) => aa(t, { signal: u, ...r }), ...n };
};
function $K(t, e) {
  const n = ia(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ca = (t, e) => a.post(
  "/restriction",
  t,
  e
), ya = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ca(o, n);
  }, ...e };
}, zK = (t) => {
  const e = ya(t);
  return i(e);
}, da = (t, e) => a.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pa = (t) => ["/restrictions/details", ...t ? [t] : []], _a = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pa(t), queryFn: ({ signal: u }) => da(t, { signal: u, ...r }), ...n };
};
function YK(t, e) {
  const n = _a(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ma = (t, e) => a.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), la = (t) => ["/liens", ...t ? [t] : []], ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? la(t), queryFn: ({ signal: u }) => ma(t, { signal: u, ...r }), ...n };
};
function XK(t, e) {
  const n = ga(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qa = (t, e, n) => a.patch(
  `/liens/${t}`,
  e,
  n
), fa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return qa(o, u, n);
  }, ...e };
}, jK = (t) => {
  const e = fa(t);
  return i(e);
}, Oa = (t, e) => a.post(
  "/opt_ins/trigger",
  t,
  e
), ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Oa(o, n);
  }, ...e };
}, ZK = (t) => {
  const e = ha(t);
  return i(e);
}, Ka = (t, e) => a.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pa = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], ba = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pa(t), queryFn: ({ signal: u }) => Ka(t, { signal: u, ...r }), ...n };
};
function JK(t, e) {
  const n = ba(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xa = (t, e) => a.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ka = (t) => ["/parafinder/businesses", ...t ? [t] : []], Ma = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ka(t), queryFn: ({ signal: u }) => xa(t, { signal: u, ...r }), ...n };
};
function tP(t, e) {
  const n = Ma(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fa = (t) => a.get(
  "/parafinder/business_types",
  t
), Ra = () => ["/parafinder/business_types"], Ba = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ra(), queryFn: ({ signal: o }) => Fa({ signal: o, ...n }), ...e };
};
function eP(t) {
  const e = Ba(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ca = (t, e) => a.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ea = (t) => ["/parafinder/summary", ...t ? [t] : []], va = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ea(t), queryFn: ({ signal: u }) => Ca(t, { signal: u, ...r }), ...n };
};
function nP(t, e) {
  const n = va(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Sa = (t, e) => a.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Aa = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], Da = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Aa(t), queryFn: ({ signal: u }) => Sa(t, { signal: u, ...r }), ...n };
};
function rP(t, e) {
  const n = Da(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ua = (t, e) => a.get(
  `/merchant_bank/accounts/${t}`,
  e
), Na = (t) => [`/merchant_bank/accounts/${t}`], Ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Na(t), queryFn: ({ signal: u }) => Ua(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function sP(t, e) {
  const n = Ga(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ta = (t, e) => a.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), Ia = (t) => [`/merchant_bank/accounts/${t}/balances`], wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ia(t), queryFn: ({ signal: u }) => Ta(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function oP(t, e) {
  const n = wa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qa = (t, e) => a.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), La = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], Wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? La(t), queryFn: ({ signal: u }) => Qa(t, { signal: u, ...r }), ...n };
};
function aP(t, e) {
  const n = Wa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Va = (t, e) => a.post(
  "/merchant_bank/accounts",
  t,
  e
), Ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Va(o, n);
  }, ...e };
}, uP = (t) => {
  const e = Ha(t);
  return i(e);
}, $a = (t, e) => a.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), za = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $a(o, n);
  }, ...e };
}, iP = (t) => {
  const e = za(t);
  return i(e);
}, Ya = (t, e, n) => a.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Xa = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], ja = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Xa(t, e), queryFn: ({ signal: y }) => Ya(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function cP(t, e, n) {
  const r = ja(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Za = (t, e) => a.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ja = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], tu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ja(t), queryFn: ({ signal: u }) => Za(t, { signal: u, ...r }), ...n };
};
function yP(t, e) {
  const n = tu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const eu = (t) => a.get(
  "/merchant_bank/transfer_fees",
  t
), nu = () => ["/merchant_bank/transfer_fees"], ru = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? nu(), queryFn: ({ signal: o }) => eu({ signal: o, ...n }), ...e };
};
function dP(t) {
  const e = ru(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const su = (t, e) => a.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ou = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], au = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ou(t), queryFn: ({ signal: u }) => su(t, { signal: u, ...r }), ...n };
};
function pP(t, e) {
  const n = au(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const uu = (t) => a.get(
  "/merchant_bank/interest",
  t
), iu = () => ["/merchant_bank/interest"], cu = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? iu(), queryFn: ({ signal: o }) => uu({ signal: o, ...n }), ...e };
};
function _P(t) {
  const e = cu(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const yu = (t, e) => a.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), du = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], pu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? du(t), queryFn: ({ signal: u }) => yu(t, { signal: u, ...r }), ...n };
};
function mP(t, e) {
  const n = pu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _u = (t, e) => a.post(
  "/merchant_bank/recipients",
  t,
  e
), mu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return _u(o, n);
  }, ...e };
}, lP = (t) => {
  const e = mu(t);
  return i(e);
}, lu = (t, e) => a.get(
  `/merchant_bank/recipients/${t}`,
  e
), gu = (t) => [`/merchant_bank/recipients/${t}`], qu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gu(t), queryFn: ({ signal: u }) => lu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function gP(t, e) {
  const n = qu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fu = (t, e) => a.delete(
  `/merchant_bank/recipients/${t}`,
  e
), Ou = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return fu(o, n);
  }, ...e };
}, qP = (t) => {
  const e = Ou(t);
  return i(e);
}, hu = (t, e, n) => a.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), Ku = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return hu(o, u, n);
  }, ...e };
}, fP = (t) => {
  const e = Ku(t);
  return i(e);
}, Pu = (t, e, n) => a.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Pu(o, u, n);
  }, ...e };
}, OP = (t) => {
  const e = bu(t);
  return i(e);
}, xu = (t, e) => a.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), ku = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xu(o, n);
  }, ...e };
}, hP = (t) => {
  const e = ku(t);
  return i(e);
}, Mu = (t, e, n) => a.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), Fu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Mu(o, u, n);
  }, ...e };
}, KP = (t) => {
  const e = Fu(t);
  return i(e);
}, Ru = (t, e) => a.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Bu = (t) => ["/merchant_bank/cards", ...t ? [t] : []], Cu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bu(t), queryFn: ({ signal: u }) => Ru(t, { signal: u, ...r }), ...n };
};
function PP(t, e) {
  const n = Cu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Eu = (t, e) => a.post(
  "/merchant_bank/cards",
  t,
  e
), vu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Eu(o, n);
  }, ...e };
}, bP = (t) => {
  const e = vu(t);
  return i(e);
}, Su = (t, e, n) => a.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), Au = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Su(o, u, n);
  }, ...e };
}, xP = (t) => {
  const e = Au(t);
  return i(e);
}, Du = (t, e) => a.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uu = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], Nu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uu(t), queryFn: ({ signal: u }) => Du(t, { signal: u, ...r }), ...n };
};
function kP(t, e) {
  const n = Nu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gu = (t, e) => a.post(
  "/merchant_bank/card/activate",
  t,
  e
), Tu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gu(o, n);
  }, ...e };
}, MP = (t) => {
  const e = Tu(t);
  return i(e);
}, Iu = (t, e) => a.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wu = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], Qu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wu(t), queryFn: ({ signal: u }) => Iu(t, { signal: u, ...r }), ...n };
};
function FP(t, e) {
  const n = Qu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Lu = (t, e) => a.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), Wu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Lu(o, n);
  }, ...e };
}, RP = (t) => {
  const e = Wu(t);
  return i(e);
}, Vu = (t, e) => a.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hu = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], $u = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hu(t), queryFn: ({ signal: u }) => Vu(t, { signal: u, ...r }), ...n };
};
function BP(t, e) {
  const n = $u(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const zu = (t, e) => a.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), Yu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return zu(o, n);
  }, ...e };
}, CP = (t) => {
  const e = Yu(t);
  return i(e);
}, Xu = (t, e) => a.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), ju = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Xu(o, n);
  }, ...e };
}, EP = (t) => {
  const e = ju(t);
  return i(e);
}, Zu = (t, e) => a.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ju = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], ti = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ju(t), queryFn: ({ signal: u }) => Zu(t, { signal: u, ...r }), ...n };
};
function vP(t, e) {
  const n = ti(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ei = (t, e) => a.post(
  "/merchant_bank/card/disputes",
  t,
  e
), ni = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ei(o, n);
  }, ...e };
}, SP = (t) => {
  const e = ni(t);
  return i(e);
}, ri = (t, e) => a.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), si = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ri(o, n);
  }, ...e };
}, AP = (t) => {
  const e = si(t);
  return i(e);
}, oi = (t, e) => a.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), ai = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return oi(o, n);
  }, ...e };
}, DP = (t) => {
  const e = ai(t);
  return i(e);
}, ui = (t, e) => a.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ii = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], ci = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ii(t), queryFn: ({ signal: u }) => ui(t, { signal: u, ...r }), ...n };
};
function UP(t, e) {
  const n = ci(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yi = (t, e) => a.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), di = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], pi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? di(t), queryFn: ({ signal: u }) => yi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function NP(t, e) {
  const n = pi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _i = (t, e) => a.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mi = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], li = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mi(t), queryFn: ({ signal: u }) => _i(t, { signal: u, ...r }), ...n };
};
function GP(t, e) {
  const n = li(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gi = (t, e) => a.post(
  "/merchant_bank/external_accounts",
  t,
  e
), qi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return gi(o, n);
  }, ...e };
}, TP = (t) => {
  const e = qi(t);
  return i(e);
}, fi = (t, e) => a.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), Oi = (t) => [`/merchant_bank/external_accounts/${t}`], hi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Oi(t), queryFn: ({ signal: u }) => fi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function IP(t, e) {
  const n = hi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ki = (t, e) => a.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), Pi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Ki(o, n);
  }, ...e };
}, wP = (t) => {
  const e = Pi(t);
  return i(e);
}, bi = (t, e, n) => a.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), xi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return bi(o, u, n);
  }, ...e };
}, QP = (t) => {
  const e = xi(t);
  return i(e);
}, ki = (t, e) => a.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), Mi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ki(o, n);
  }, ...e };
}, LP = (t) => {
  const e = Mi(t);
  return i(e);
}, Fi = (t, e, n) => a.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), Ri = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Fi(o, u, n);
  }, ...e };
}, WP = (t) => {
  const e = Ri(t);
  return i(e);
}, Bi = (t, e) => a.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ci = (t) => ["/debts", ...t ? [t] : []], Ei = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ci(t), queryFn: ({ signal: u }) => Bi(t, { signal: u, ...r }), ...n };
};
function VP(t, e) {
  const n = Ei(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vi = (t, e) => a.post(
  "/debts",
  t,
  e
), Si = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vi(o, n);
  }, ...e };
}, HP = (t) => {
  const e = Si(t);
  return i(e);
}, Ai = (t, e) => a.post(
  "/debts/check_complete",
  t,
  e
), Di = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ai(o, n);
  }, ...e };
}, $P = (t) => {
  const e = Di(t);
  return i(e);
}, Ui = (t, e) => a.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ni = (t) => ["/debt_checks", ...t ? [t] : []], Gi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ni(t), queryFn: ({ signal: u }) => Ui(t, { signal: u, ...r }), ...n };
};
function zP(t, e) {
  const n = Gi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ti = (t, e) => a.post(
  "/card/close_account",
  t,
  e
), Ii = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ti(o, n);
  }, ...e };
}, YP = (t) => {
  const e = Ii(t);
  return i(e);
}, wi = (t, e) => a.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qi = (t) => ["/financial_reviews", ...t ? [t] : []], Li = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qi(t), queryFn: ({ signal: u }) => wi(t, { signal: u, ...r }), ...n };
};
function XP(t, e) {
  const n = Li(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wi = (t, e) => a.post(
  "/financial_reviews",
  t,
  e
), Vi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Wi(o, n);
  }, ...e };
}, jP = (t) => {
  const e = Vi(t);
  return i(e);
}, Hi = (t, e) => a.get(
  "/accounting_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $i = (t) => ["/accounting_info", ...t ? [t] : []], zi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $i(t), queryFn: ({ signal: u }) => Hi(t, { signal: u, ...r }), ...n };
};
function ZP(t, e) {
  const n = zi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yi = (t, e) => a.post(
  "/accounting_info",
  t,
  e
), Xi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Yi(o, n);
  }, ...e };
}, JP = (t) => {
  const e = Xi(t);
  return i(e);
}, ji = (t, e) => a.post(
  "/tax_records",
  t,
  e
), Zi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ji(o, n);
  }, ...e };
}, tb = (t) => {
  const e = Zi(t);
  return i(e);
}, Ji = (t, e) => a.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tc = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], ec = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tc(t), queryFn: ({ signal: u }) => Ji(t, { signal: u, ...r }), ...n };
};
function eb(t, e) {
  const n = ec(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const nc = (t, e) => a.post(
  "/officer_subsidiary_records",
  t,
  e
), rc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return nc(o, n);
  }, ...e };
}, nb = (t) => {
  const e = rc(t);
  return i(e);
}, sc = (t) => a.get(
  "/analytics/cube_token",
  t
), oc = () => ["/analytics/cube_token"], ac = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? oc(), queryFn: ({ signal: o }) => sc({ signal: o, ...n }), ...e };
};
function rb(t) {
  const e = ac(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const uc = (t, e) => a.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ic = (t) => ["/logs/api_request", ...t ? [t] : []], cc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ic(t), queryFn: ({ signal: u }) => uc(t, { signal: u, ...r }), ...n };
};
function sb(t, e) {
  const n = cc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yc = (t) => a.get(
  "/marketing/offer_csvs",
  t
), dc = () => ["/marketing/offer_csvs"], pc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? dc(), queryFn: ({ signal: o }) => yc({ signal: o, ...n }), ...e };
};
function ob(t) {
  const e = pc(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const _c = (t) => a.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), mc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => _c(n), ...e };
}, ab = (t) => {
  const e = mc(t);
  return i(e);
}, lc = (t, e) => a.get(
  "/marketing/emails/batch",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gc = (t) => ["/marketing/emails/batch", ...t ? [t] : []], qc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gc(t), queryFn: ({ signal: u }) => lc(t, { signal: u, ...r }), ...n };
};
function ub(t, e) {
  const n = qc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fc = (t, e) => a.post(
  "/marketing/emails/batch",
  t,
  e
), Oc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return fc(o, n);
  }, ...e };
}, ib = (t) => {
  const e = Oc(t);
  return i(e);
}, hc = (t, e) => a.get(
  "/marketing/emails/scheduled_marketing_config",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kc = (t) => ["/marketing/emails/scheduled_marketing_config", ...t ? [t] : []], Pc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kc(t), queryFn: ({ signal: u }) => hc(t, { signal: u, ...r }), ...n };
};
function cb(t, e) {
  const n = Pc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const bc = (t, e) => a.post(
  "/marketing/emails/scheduled_marketing_config",
  t,
  e
), xc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bc(o, n);
  }, ...e };
}, yb = (t) => {
  const e = xc(t);
  return i(e);
}, kc = (t, e) => a.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mc = (t) => ["/businesses/notifications", ...t ? [t] : []], Fc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mc(t), queryFn: ({ signal: u }) => kc(t, { signal: u, ...r }), ...n };
};
function db(t, e) {
  const n = Fc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rc = (t, e) => a.post(
  "/businesses/notifications",
  t,
  e
), Bc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Rc(o, n);
  }, ...e };
}, pb = (t) => {
  const e = Bc(t);
  return i(e);
}, Cc = (t, e) => a.post(
  "/terms_of_service_mark_shown",
  t,
  e
), Ec = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Cc(o, n);
  }, ...e };
}, _b = (t) => {
  const e = Ec(t);
  return i(e);
}, vc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Sc = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], Ac = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Sc(t, e), queryFn: ({ signal: y }) => vc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function mb(t, e, n) {
  const r = Ac(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Dc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), Uc = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], Nc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uc(t), queryFn: ({ signal: u }) => Dc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function lb(t, e) {
  const n = Nc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), Tc = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], Ic = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Tc(t), queryFn: ({ signal: u }) => Gc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function gb(t, e) {
  const n = Ic(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), Qc = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], Lc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qc(t), queryFn: ({ signal: u }) => wc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function qb(t, e) {
  const n = Lc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Vc = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], Hc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Vc(t, e), queryFn: ({ signal: y }) => Wc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function fb(t, e, n) {
  const r = Hc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const $c = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), zc = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], Yc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? zc(t, e), queryFn: ({ signal: y }) => $c(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Ob(t, e, n) {
  const r = Yc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Xc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), jc = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Zc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? jc(t, e), queryFn: ({ signal: y }) => Xc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function hb(t, e, n) {
  const r = Zc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Jc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), ty = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], ey = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ty(t), queryFn: ({ signal: u }) => Jc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Kb(t, e) {
  const n = ey(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ny = (t, e) => a.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ry = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], sy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ry(t), queryFn: ({ signal: u }) => ny(t, { signal: u, ...r }), ...n };
};
function Pb(t, e) {
  const n = sy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oy = (t, e) => a.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ay = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], uy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ay(t), queryFn: ({ signal: u }) => oy(t, { signal: u, ...r }), ...n };
};
function bb(t, e) {
  const n = uy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const iy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cy = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], yy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cy(t), queryFn: ({ signal: u }) => iy(t, { signal: u, ...r }), ...n };
};
function xb(t, e) {
  const n = yy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const dy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), py = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], _y = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? py(t), queryFn: ({ signal: u }) => dy(t, { signal: u, ...r }), ...n };
};
function kb(t, e) {
  const n = _y(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const my = (t, e) => a.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ly = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], gy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ly(t), queryFn: ({ signal: u }) => my(t, { signal: u, ...r }), ...n };
};
function Mb(t, e) {
  const n = gy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fy = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], Oy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fy(t), queryFn: ({ signal: u }) => qy(t, { signal: u, ...r }), ...n };
};
function Fb(t, e) {
  const n = Oy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const hy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ky = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], Py = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ky(t), queryFn: ({ signal: u }) => hy(t, { signal: u, ...r }), ...n };
};
function Rb(t, e) {
  const n = Py(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const by = (t, e) => a.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xy = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], ky = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xy(t), queryFn: ({ signal: u }) => by(t, { signal: u, ...r }), ...n };
};
function Bb(t, e) {
  const n = ky(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const My = (t) => a.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), Fy = () => ["/partner_dashboard/platform_tab/partner_support_info"], Ry = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Fy(), queryFn: ({ signal: o }) => My({ signal: o, ...n }), ...e };
};
function Cb(t) {
  const e = Ry(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const By = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), Cy = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], Ey = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cy(t), queryFn: ({ signal: u }) => By(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Eb(t, e) {
  const n = Ey(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vy = (t) => a.get(
  "/partner_dashboard/partner_event/subscriptions/list_user_subscriptions",
  t
), Sy = () => ["/partner_dashboard/partner_event/subscriptions/list_user_subscriptions"], Ay = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Sy(), queryFn: ({ signal: o }) => vy({ signal: o, ...n }), ...e };
};
function vb(t) {
  const e = Ay(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Dy = (t, e) => a.get(
  "/partner_dashboard/partner_event/web_notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uy = (t) => ["/partner_dashboard/partner_event/web_notifications", ...t ? [t] : []], Ny = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uy(t), queryFn: ({ signal: u }) => Dy(t, { signal: u, ...r }), ...n };
};
function Sb(t, e) {
  const n = Ny(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gy = (t, e) => a.post(
  "/partner_dashboard/partner_event/web_notifications/mark_all_read",
  t,
  e
), Ty = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gy(o, n);
  }, ...e };
}, Ab = (t) => {
  const e = Ty(t);
  return i(e);
}, Iy = (t, e) => a.post(
  "/partner_dashboard/partner_event/web_notifications/mark_one_read",
  t,
  e
), wy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Iy(o, n);
  }, ...e };
}, Db = (t) => {
  const e = wy(t);
  return i(e);
}, Qy = (t, e) => a.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions",
  t,
  e
), Ly = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Qy(o, n);
  }, ...e };
}, Ub = (t) => {
  const e = Ly(t);
  return i(e);
}, Wy = (t, e) => a.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions_for_event_type",
  t,
  e
), Vy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Wy(o, n);
  }, ...e };
}, Nb = (t) => {
  const e = Vy(t);
  return i(e);
}, Hy = (t) => a.get(
  "/partner_dashboard/crm/integration",
  t
), $y = () => ["/partner_dashboard/crm/integration"], zy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? $y(), queryFn: ({ signal: o }) => Hy({ signal: o, ...n }), ...e };
};
function Gb(t) {
  const e = zy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Yy = (t) => a.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), Xy = () => ["/partner_dashboard/valid_object_id_prefixes"], jy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Xy(), queryFn: ({ signal: o }) => Yy({ signal: o, ...n }), ...e };
};
function Tb(t) {
  const e = jy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Zy = (t, e) => a.get(
  `/partner_dashboard/business/owner_auth/${t}`,
  e
), Jy = (t) => [`/partner_dashboard/business/owner_auth/${t}`], td = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jy(t), queryFn: ({ signal: u }) => Zy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Ib(t, e) {
  const n = td(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ed = (t, e) => a.post(
  "/v1/sandbox/generate_event/capital_product_offer/created",
  t,
  e
), nd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ed(o, n);
  }, ...e };
}, wb = (t) => {
  const e = nd(t);
  return i(e);
}, rd = (t, e) => a.post(
  "/v1/sandbox/generate_event/capital_product_offer/closed",
  t,
  e
), sd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return rd(o, n);
  }, ...e };
}, Qb = (t) => {
  const e = sd(t);
  return i(e);
}, od = (t, e) => a.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ad = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], ud = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ad(t), queryFn: ({ signal: u }) => od(t, { signal: u, ...r }), ...n };
};
function Lb(t, e) {
  const n = ud(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const id = (t, e) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), cd = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], yd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cd(t), queryFn: ({ signal: u }) => id(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Wb(t, e) {
  const n = yd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const dd = (t, e, n) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), pd = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], _d = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? pd(t, e), queryFn: ({ signal: y }) => dd(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Vb(t, e, n) {
  const r = _d(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const md = (t, e) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), ld = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], gd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ld(t), queryFn: ({ signal: u }) => md(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Hb(t, e) {
  const n = gd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qd = (t, e) => a.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), fd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return qd(o, n);
  }, ...e };
}, $b = (t) => {
  const e = fd(t);
  return i(e);
}, Od = (t, e) => a.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), hd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Od(o, n);
  }, ...e };
}, zb = (t) => {
  const e = hd(t);
  return i(e);
}, Kd = (t, e) => a.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pd = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], bd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pd(t), queryFn: ({ signal: u }) => Kd(t, { signal: u, ...r }), ...n };
};
function Yb(t, e) {
  const n = bd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xd = (t, e, n) => a.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), kd = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], Md = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? kd(t, e), queryFn: ({ signal: y }) => xd(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Xb(t, e, n) {
  const r = Md(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Fd = (t, e) => a.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), Rd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Fd(o, n);
  }, ...e };
}, jb = (t) => {
  const e = Rd(t);
  return i(e);
}, Bd = (t, e) => a.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), Cd = (t) => [`/partner_dashboard/crm/event_info/${t}`], Ed = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cd(t), queryFn: ({ signal: u }) => Bd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Zb(t, e) {
  const n = Ed(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vd = (t) => a.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), Sd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => vd(n), ...e };
}, Jb = (t) => {
  const e = Sd(t);
  return i(e);
}, Ad = (t) => a.get(
  "/partner_dashboard/crm/sync",
  t
), Dd = () => ["/partner_dashboard/crm/sync"], Ud = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Dd(), queryFn: ({ signal: o }) => Ad({ signal: o, ...n }), ...e };
};
function tx(t) {
  const e = Ud(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Nd = (t) => a.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), Gd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Nd(n), ...e };
}, ex = (t) => {
  const e = Gd(t);
  return i(e);
}, Td = (t, e) => a.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Id = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], wd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Id(t), queryFn: ({ signal: u }) => Td(t, { signal: u, ...r }), ...n };
};
function nx(t, e) {
  const n = wd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qd = (t, e) => a.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), Ld = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Qd(o, n);
  }, ...e };
}, rx = (t) => {
  const e = Ld(t);
  return i(e);
}, Wd = (t, e) => a.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), Vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Wd(o, n);
  }, ...e };
}, sx = (t) => {
  const e = Vd(t);
  return i(e);
}, Hd = (t, e, n) => a.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), $d = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Hd(o, u, n);
  }, ...e };
}, ox = (t) => {
  const e = $d(t);
  return i(e);
}, zd = (t, e) => a.get(
  `/partner_dashboard/capital_tab/csv_export/${t}`,
  e
), Yd = (t) => [`/partner_dashboard/capital_tab/csv_export/${t}`], Xd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yd(t), queryFn: ({ signal: u }) => zd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ax(t, e) {
  const n = Xd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jd = (t, e) => a.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zd = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], Jd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zd(t), queryFn: ({ signal: u }) => jd(t, { signal: u, ...r }), ...n };
};
function ux(t, e) {
  const n = Jd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tp = (t, e) => a.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), ep = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return tp(o, n);
  }, ...e };
}, ix = (t) => {
  const e = ep(t);
  return i(e);
}, np = (t) => a.get(
  "/partner_dashboard/fee_discounts/request_access",
  t
), rp = () => ["/partner_dashboard/fee_discounts/request_access"], sp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? rp(), queryFn: ({ signal: o }) => np({ signal: o, ...n }), ...e };
};
function cx(t) {
  const e = sp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const op = (t, e) => a.post(
  "/partner_dashboard/fee_discounts/request_access",
  t,
  e
), ap = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return op(o, n);
  }, ...e };
}, yx = (t) => {
  const e = ap(t);
  return i(e);
}, up = (t, e) => a.get(
  "/partner_dashboard/fee_discounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ip = (t) => ["/partner_dashboard/fee_discounts", ...t ? [t] : []], cp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ip(t), queryFn: ({ signal: u }) => up(t, { signal: u, ...r }), ...n };
};
function dx(t, e) {
  const n = cp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yp = (t, e) => a.post(
  "/partner_dashboard/fee_discounts",
  t,
  e
), dp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return yp(o, n);
  }, ...e };
}, px = (t) => {
  const e = dp(t);
  return i(e);
}, pp = (t, e) => a.get(
  `/partner_dashboard/fee_discounts/${t}`,
  e
), _p = (t) => [`/partner_dashboard/fee_discounts/${t}`], mp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _p(t), queryFn: ({ signal: u }) => pp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function _x(t, e) {
  const n = mp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lp = (t, e, n) => a.get(
  `/partner_dashboard/fee_discounts/${t}/businesses`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), gp = (t, e) => [`/partner_dashboard/fee_discounts/${t}/businesses`, ...e ? [e] : []], qp = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? gp(t, e), queryFn: ({ signal: y }) => lp(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function mx(t, e, n) {
  const r = qp(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const fp = (t) => a.get(
  "/partner_dashboard/revenue_share",
  t
), Op = () => ["/partner_dashboard/revenue_share"], hp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Op(), queryFn: ({ signal: o }) => fp({ signal: o, ...n }), ...e };
};
function lx(t) {
  const e = hp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Kp = (t, e) => a.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pp = (t) => ["/rutter_connections", ...t ? [t] : []], bp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pp(t), queryFn: ({ signal: u }) => Kp(t, { signal: u, ...r }), ...n };
};
function gx(t, e) {
  const n = bp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xp = (t, e) => a.post(
  "/rutter_connections",
  t,
  e
), kp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xp(o, n);
  }, ...e };
}, qx = (t) => {
  const e = kp(t);
  return i(e);
}, Mp = (t, e) => a.get(
  "/rutter_connection_options",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fp = (t) => ["/rutter_connection_options", ...t ? [t] : []], Rp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fp(t), queryFn: ({ signal: u }) => Mp(t, { signal: u, ...r }), ...n };
};
function fx(t, e) {
  const n = Rp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Bp = (t, e) => a.post(
  "/mark_accounting_integration_seen",
  t,
  e
), Cp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bp(o, n);
  }, ...e };
}, Ox = (t) => {
  const e = Cp(t);
  return i(e);
}, Ep = (t) => a.get(
  "/sardine/session_id",
  t
), vp = () => ["/sardine/session_id"], Sp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? vp(), queryFn: ({ signal: o }) => Ep({ signal: o, ...n }), ...e };
};
function hx(t) {
  const e = Sp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ap = (t, e) => a.post(
  "/sardine/session_id",
  t,
  e
), Dp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ap(o, n);
  }, ...e };
}, Kx = (t) => {
  const e = Dp(t);
  return i(e);
}, Up = (t) => a.get(
  "/partner/widget_configs",
  t
), Np = () => ["/partner/widget_configs"], Gp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Np(), queryFn: ({ signal: o }) => Up({ signal: o, ...n }), ...e };
};
function Px(t) {
  const e = Gp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Tp = (t, e) => a.patch(
  "/partner/widget_configs",
  t,
  e
), Ip = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Tp(o, n);
  }, ...e };
}, bx = (t) => {
  const e = Ip(t);
  return i(e);
}, wp = (t, e) => a.get(
  `/merchant_bank/applications/${t}`,
  e
), Qp = (t) => [`/merchant_bank/applications/${t}`], Lp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qp(t), queryFn: ({ signal: u }) => wp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function xx(t, e) {
  const n = Lp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wp = (t, e) => a.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vp = (t) => ["/merchant_bank/applications", ...t ? [t] : []], Hp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vp(t), queryFn: ({ signal: u }) => Wp(t, { signal: u, ...r }), ...n };
};
function kx(t, e) {
  const n = Hp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $p = (t, e) => a.post(
  "/merchant_bank/applications",
  t,
  e
), zp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $p(o, n);
  }, ...e };
}, Mx = (t) => {
  const e = zp(t);
  return i(e);
}, Yp = (t, e) => a.post(
  "/webhooks/plaid/general",
  t,
  e
), Xp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Yp(o, n);
  }, ...e };
}, Fx = (t) => {
  const e = Xp(t);
  return i(e);
}, jp = (t, e) => a.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zp = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Jp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zp(t), queryFn: ({ signal: u }) => jp(t, { signal: u, ...r }), ...n };
};
function Rx(t, e) {
  const n = Jp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const t_ = (t) => a.get(
  "/naics",
  t
), e_ = () => ["/naics"], n_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? e_(), queryFn: ({ signal: o }) => t_({ signal: o, ...n }), ...e };
};
function Bx(t) {
  const e = n_(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const r_ = (t, e, n, r) => a.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), s_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: o, phoneNumberType: u, data: y } = s ?? {};
    return r_(o, u, y, n);
  }, ...e };
}, Cx = (t) => {
  const e = s_(t);
  return i(e);
}, o_ = (t, e, n, r) => a.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), a_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: o, phoneNumberType: u, data: y } = s ?? {};
    return o_(o, u, y, n);
  }, ...e };
}, Ex = (t) => {
  const e = a_(t);
  return i(e);
}, u_ = (t, e) => a.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), i_ = (t) => ["/funding/pad_agreement", ...t ? [t] : []], c_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? i_(t), queryFn: ({ signal: u }) => u_(t, { signal: u, ...r }), ...n };
};
function vx(t, e) {
  const n = c_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const y_ = (t, e) => a.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), d_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return y_(o, n);
  }, ...e };
}, Sx = (t) => {
  const e = d_(t);
  return i(e);
}, p_ = (t, e) => a.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), __ = (t) => ["/templates", ...t ? [t] : []], m_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? __(t), queryFn: ({ signal: u }) => p_(t, { signal: u, ...r }), ...n };
};
function Ax(t, e) {
  const n = m_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const l_ = (t, e) => a.post(
  "/templates",
  t,
  e
), g_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return l_(o, n);
  }, ...e };
}, Dx = (t) => {
  const e = g_(t);
  return i(e);
}, q_ = (t, e) => a.patch(
  "/templates",
  t,
  e
), f_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return q_(o, n);
  }, ...e };
}, Ux = (t) => {
  const e = f_(t);
  return i(e);
}, O_ = (t, e) => a.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), h_ = (t) => ["/template_mock", ...t ? [t] : []], K_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? h_(t), queryFn: ({ signal: u }) => O_(t, { signal: u, ...r }), ...n };
};
function Nx(t, e) {
  const n = K_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const P_ = (t, e) => a.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), b_ = (t) => ["/template_snapshots", ...t ? [t] : []], x_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? b_(t), queryFn: ({ signal: u }) => P_(t, { signal: u, ...r }), ...n };
};
function Gx(t, e) {
  const n = x_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const k_ = (t, e) => a.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), M_ = (t) => ["/template_snapshot_urls", ...t ? [t] : []], F_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? M_(t), queryFn: ({ signal: u }) => k_(t, { signal: u, ...r }), ...n };
};
function Tx(t, e) {
  const n = F_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const R_ = (t) => a.get(
  "/template_types",
  t
), B_ = () => ["/template_types"], C_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? B_(), queryFn: ({ signal: o }) => R_({ signal: o, ...n }), ...e };
};
function Ix(t) {
  const e = C_(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const E_ = (t, e) => a.post(
  "/template_types",
  t,
  e
), v_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return E_(o, n);
  }, ...e };
}, wx = (t) => {
  const e = v_(t);
  return i(e);
}, S_ = (t, e) => a.post(
  "/manual_repayment",
  t,
  e
), A_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return S_(o, n);
  }, ...e };
}, Qx = (t) => {
  const e = A_(t);
  return i(e);
}, D_ = (t, e) => a.post(
  "/forgive_capital_fee",
  t,
  e
), U_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return D_(o, n);
  }, ...e };
}, Lx = (t) => {
  const e = U_(t);
  return i(e);
}, N_ = (t, e) => a.post(
  "/mfa/verify_account_details",
  t,
  e
), G_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return N_(o, n);
  }, ...e };
}, Wx = (t) => {
  const e = G_(t);
  return i(e);
}, T_ = (t) => a.patch(
  "/mfa/extend_session",
  void 0,
  t
), I_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => T_(n), ...e };
}, Vx = (t) => {
  const e = I_(t);
  return i(e);
}, w_ = (t, e) => a.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q_ = (t) => ["/merchant_bank/offers", ...t ? [t] : []], L_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q_(t), queryFn: ({ signal: u }) => w_(t, { signal: u, ...r }), ...n };
};
function Hx(t, e) {
  const n = L_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const W_ = (t, e) => a.post(
  "/merchant_bank/offers",
  t,
  e
), V_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return W_(o, n);
  }, ...e };
}, $x = (t) => {
  const e = V_(t);
  return i(e);
}, H_ = (t, e) => a.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $_ = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], z_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $_(t), queryFn: ({ signal: u }) => H_(t, { signal: u, ...r }), ...n };
};
function zx(t, e) {
  const n = z_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Y_ = (t, e) => a.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), X_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Y_(o, n);
  }, ...e };
}, Yx = (t) => {
  const e = X_(t);
  return i(e);
}, j_ = (t, e) => a.post(
  "/auth/login/validate_otp",
  t,
  e
), Z_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return j_(o, n);
  }, ...e };
}, Xx = (t) => {
  const e = Z_(t);
  return i(e);
}, J_ = (t, e) => a.post(
  "/auth/login/start",
  t,
  e
), tm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return J_(o, n);
  }, ...e };
}, jx = (t) => {
  const e = tm(t);
  return i(e);
}, em = (t, e) => a.post(
  "/auth/token/exchange",
  t,
  e
), nm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return em(o, n);
  }, ...e };
}, Zx = (t) => {
  const e = nm(t);
  return i(e);
}, rm = (t, e) => a.get(
  `/auth/management/${t}/saml`,
  e
), sm = (t) => [`/auth/management/${t}/saml`], om = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sm(t), queryFn: ({ signal: u }) => rm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Jx(t, e) {
  const n = om(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const am = (t, e, n) => a.post(
  `/auth/management/${t}/saml`,
  e,
  n
), um = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, data: u } = s ?? {};
    return am(o, u, n);
  }, ...e };
}, tk = (t) => {
  const e = um(t);
  return i(e);
}, im = (t, e, n) => a.delete(
  `/auth/management/${t}/saml/${e}`,
  n
), cm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, samlConfigId: u } = s ?? {};
    return im(o, u, n);
  }, ...e };
}, ek = (t) => {
  const e = cm(t);
  return i(e);
}, ym = (t, e, n, r) => a.patch(
  `/auth/management/${t}/saml/${e}`,
  n,
  r
), dm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, samlConfigId: u, data: y } = s ?? {};
    return ym(o, u, y, n);
  }, ...e };
}, nk = (t) => {
  const e = dm(t);
  return i(e);
}, pm = (t, e) => a.post(
  "/auth/email_otp/verify",
  t,
  e
), _m = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pm(o, n);
  }, ...e };
}, rk = (t) => {
  const e = _m(t);
  return i(e);
}, mm = (t, e) => a.post(
  "/auth/email_otp/v2/verify",
  t,
  e
), lm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return mm(o, n);
  }, ...e };
}, sk = (t) => {
  const e = lm(t);
  return i(e);
}, gm = (t, e) => a.post(
  "/auth/email_otp/start",
  t,
  e
), qm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return gm(o, n);
  }, ...e };
}, ok = (t) => {
  const e = qm(t);
  return i(e);
}, fm = (t, e) => a.get(
  `/introductory_offer/underwrite/${t}`,
  e
), Om = (t) => [`/introductory_offer/underwrite/${t}`], hm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Om(t), queryFn: ({ signal: u }) => fm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ak(t, e) {
  const n = hm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Km = (t, e) => a.post(
  "/introductory_offer/underwrite",
  t,
  e
), Pm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Km(o, n);
  }, ...e };
}, uk = (t) => {
  const e = Pm(t);
  return i(e);
}, bm = (t, e) => a.get(
  "/card/balances",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xm = (t) => ["/card/balances", ...t ? [t] : []], km = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xm(t), queryFn: ({ signal: u }) => bm(t, { signal: u, ...r }), ...n };
};
function ik(t, e) {
  const n = km(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Mm = (t) => a.get(
  "/cardholders",
  t
), Fm = () => ["/cardholders"], Rm = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Fm(), queryFn: ({ signal: o }) => Mm({ signal: o, ...n }), ...e };
};
function ck(t) {
  const e = Rm(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Bm = (t, e, n) => a.patch(
  `/cardholders/${t}`,
  e,
  n
), Cm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { cardholderId: o, data: u } = s ?? {};
    return Bm(o, u, n);
  }, ...e };
}, yk = (t) => {
  const e = Cm(t);
  return i(e);
}, Em = (t, e) => a.get(
  "/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vm = (t) => ["/card/access_token", ...t ? [t] : []], Sm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vm(t), queryFn: ({ signal: u }) => Em(t, { signal: u, ...r }), ...n };
};
function dk(t, e) {
  const n = Sm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Am = (t, e) => a.get(
  "/card/lifecycle_states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dm = (t) => ["/card/lifecycle_states", ...t ? [t] : []], Um = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dm(t), queryFn: ({ signal: u }) => Am(t, { signal: u, ...r }), ...n };
};
function pk(t, e) {
  const n = Um(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Nm = (t, e) => a.get(
  "/card/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gm = (t) => ["/card/transactions", ...t ? [t] : []], Tm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gm(t), queryFn: ({ signal: u }) => Nm(t, { signal: u, ...r }), ...n };
};
function _k(t, e) {
  const n = Tm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Im = (t, e) => a.get(
  "/card/pending_transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wm = (t) => ["/card/pending_transactions", ...t ? [t] : []], Qm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wm(t), queryFn: ({ signal: u }) => Im(t, { signal: u, ...r }), ...n };
};
function mk(t, e) {
  const n = Qm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Lm = (t, e) => a.get(
  `/orders/${t}`,
  e
), Wm = (t) => [`/orders/${t}`], Vm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wm(t), queryFn: ({ signal: u }) => Lm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function lk(t, e) {
  const n = Vm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hm = (t, e) => a.get(
  `/refund/${t}`,
  e
), $m = (t) => [`/refund/${t}`], zm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $m(t), queryFn: ({ signal: u }) => Hm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function gk(t, e) {
  const n = zm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ym = (t, e) => a.get(
  "/opt_ins",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xm = (t) => ["/opt_ins", ...t ? [t] : []], jm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xm(t), queryFn: ({ signal: u }) => Ym(t, { signal: u, ...r }), ...n };
};
function qk(t, e) {
  const n = jm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zm = (t, e) => a.post(
  "/opt_ins",
  t,
  e
), Jm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Zm(o, n);
  }, ...e };
}, fk = (t) => {
  const e = Jm(t);
  return i(e);
}, tl = (t, e) => a.post(
  "/bulk_opt_in",
  t,
  e
), el = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return tl(o, n);
  }, ...e };
}, Ok = (t) => {
  const e = el(t);
  return i(e);
}, nl = (t, e) => a.get(
  "/opt_out_eligibility",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rl = (t) => ["/opt_out_eligibility", ...t ? [t] : []], sl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rl(t), queryFn: ({ signal: u }) => nl(t, { signal: u, ...r }), ...n };
};
function hk(t, e) {
  const n = sl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ol = (t, e) => a.post(
  "/opt_out",
  t,
  e
), al = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ol(o, n);
  }, ...e };
}, Kk = (t) => {
  const e = al(t);
  return i(e);
}, ul = (t, e) => a.put(
  "/sandbox/capital_product_test_offer",
  t,
  e
), il = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ul(o, n);
  }, ...e };
}, Pk = (t) => {
  const e = il(t);
  return i(e);
}, cl = (t, e) => a.patch(
  "/sandbox/capital_product_fund",
  t,
  e
), yl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return cl(o, n);
  }, ...e };
}, bk = (t) => {
  const e = yl(t);
  return i(e);
}, dl = (t, e) => a.patch(
  "/sandbox/capital_product_funding_checklist",
  t,
  e
), pl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return dl(o, n);
  }, ...e };
}, xk = (t) => {
  const e = pl(t);
  return i(e);
}, _l = (t, e) => a.patch(
  "/sandbox/capital_product_top_up",
  t,
  e
), ml = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return _l(o, n);
  }, ...e };
}, kk = (t) => {
  const e = ml(t);
  return i(e);
}, ll = (t, e) => a.post(
  "/sandbox/capital_product_pay_off",
  t,
  e
), gl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ll(o, n);
  }, ...e };
}, Mk = (t) => {
  const e = gl(t);
  return i(e);
}, ql = (t, e) => a.post(
  "/sandbox/amazon_lock_bank_account",
  t,
  e
), fl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ql(o, n);
  }, ...e };
}, Fk = (t) => {
  const e = fl(t);
  return i(e);
}, Ol = (t, e) => a.post(
  "/sandbox/break_bank_account",
  t,
  e
), hl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ol(o, n);
  }, ...e };
}, Rk = (t) => {
  const e = hl(t);
  return i(e);
}, Kl = (t, e) => a.post(
  "/sandbox/repayments",
  t,
  e
), Pl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Kl(o, n);
  }, ...e };
}, Bk = (t) => {
  const e = Pl(t);
  return i(e);
}, bl = (t, e) => a.patch(
  "/sandbox/repayments",
  t,
  e
), xl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bl(o, n);
  }, ...e };
}, Ck = (t) => {
  const e = xl(t);
  return i(e);
}, kl = (t, e) => a.post(
  "/sandbox/merchant_bank/setup_business",
  t,
  e
), Ml = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return kl(o, n);
  }, ...e };
}, Ek = (t) => {
  const e = Ml(t);
  return i(e);
}, Fl = (t, e) => a.put(
  "/sandbox/fee_discount",
  t,
  e
), Rl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Fl(o, n);
  }, ...e };
}, vk = (t) => {
  const e = Rl(t);
  return i(e);
}, Bl = (t, e) => a.get(
  "/repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cl = (t) => ["/repayments", ...t ? [t] : []], El = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cl(t), queryFn: ({ signal: u }) => Bl(t, { signal: u, ...r }), ...n };
};
function Sk(t, e) {
  const n = El(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vl = (t, e) => a.get(
  "/businesses/show_top_up_offer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sl = (t) => ["/businesses/show_top_up_offer", ...t ? [t] : []], Al = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sl(t), queryFn: ({ signal: u }) => vl(t, { signal: u, ...r }), ...n };
};
function Ak(t, e) {
  const n = Al(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Dl = (t, e) => a.get(
  "/funding/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ul = (t) => ["/funding/plaid_link_token", ...t ? [t] : []], Nl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ul(t), queryFn: ({ signal: u }) => Dl(t, { signal: u, ...r }), ...n };
};
function Dk(t, e) {
  const n = Nl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gl = (t, e) => a.get(
  "/statements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Tl = (t) => ["/statements", ...t ? [t] : []], Il = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Tl(t), queryFn: ({ signal: u }) => Gl(t, { signal: u, ...r }), ...n };
};
function Uk(t, e) {
  const n = Il(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wl = (t, e) => a.get(
  "/early_manual_repayment_whitelist",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ql = (t) => ["/early_manual_repayment_whitelist", ...t ? [t] : []], Ll = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ql(t), queryFn: ({ signal: u }) => wl(t, { signal: u, ...r }), ...n };
};
function Nk(t, e) {
  const n = Ll(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wl = (t, e) => a.patch(
  "/",
  t,
  e
), Vl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Wl(o, n);
  }, ...e };
}, Gk = (t) => {
  const e = Vl(t);
  return i(e);
}, Hl = (t, e) => a.get(
  "/bnpl/underwrite",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $l = (t) => ["/bnpl/underwrite", ...t ? [t] : []], zl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $l(t), queryFn: ({ signal: u }) => Hl(t, { signal: u, ...r }), ...n };
};
function Tk(t, e) {
  const n = zl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yl = (t, e) => a.post(
  "/bnpl/underwrite",
  t,
  e
), Xl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Yl(o, n);
  }, ...e };
}, Ik = (t) => {
  const e = Xl(t);
  return i(e);
}, wk = (t) => i({
  mutationFn: (e) => a.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), Qk = (t) => i({
  mutationFn: ({
    id: e,
    ...n
  }) => a.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), Lk = (t) => i({
  mutationFn: (e) => a.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), Wk = (t) => i({
  mutationFn: (e) => a.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), Vk = (t) => i({
  mutationFn: (e) => a.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), Hk = (t) => i({
  mutationFn: (e) => a.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), $k = (t) => i({
  mutationFn: (e) => a.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), zk = (t) => i({
  mutationFn: (e) => a.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), Yk = (t) => i({
  mutationFn: (e) => a.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), Xk = (t) => i({
  mutationFn: (e) => a.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), jk = (t) => i({
  mutationFn: (e) => a.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), Zk = (t) => i({
  mutationFn: (e) => a.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), Jk = (t) => i({
  mutationFn: (e) => a.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), tM = (t) => i({
  mutationFn: (e) => a.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), eM = (t) => i({
  mutationFn: (e) => a.post("/tickets", e, t == null ? void 0 : t.axios)
}), nM = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/sandbox/bank_account/{id}/verify",
    method: "post",
    name: "Verify Bank Account (Sandbox)"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests/initiate",
    method: "patch",
    name: "Initiate Net Settlement Payment Request"
  },
  {
    path: "/v1/sandbox/net_settlement_payment_requests",
    method: "post",
    name: "Create Net Settlement Payment Request"
  },
  {
    path: "/v1/orders/{id}",
    method: "get",
    name: "Get Order"
  },
  {
    path: "/v1/orders",
    method: "get",
    name: "List Orders"
  },
  {
    path: "/v1/orders",
    method: "post",
    name: "Create Order"
  },
  {
    path: "/v1/orders/{id}/cancel",
    method: "post",
    name: "Cancel Order"
  },
  {
    path: "/v1/orders/{id}/finalize",
    method: "post",
    name: "Finalize Order"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/refunds",
    method: "get",
    name: "List Refunds"
  },
  {
    path: "/v1/refunds",
    method: "post",
    name: "Create Refund"
  },
  {
    path: "/v1/refunds/{id}",
    method: "get",
    name: "Get Refund"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/wallets/balance",
    method: "post",
    name: "Add Business Wallet Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  }
];
export {
  aO as AccountOwner,
  oO as AddressType,
  sO as AdverseActionReason,
  rO as BankAccountInfoDataShare,
  nO as BankAccountStatus,
  eO as BusinessProductType,
  tO as CapitalProductActivityType,
  Jf as CapitalProductApplicationStatus,
  Zf as CapitalProductApplicationStatus1,
  jf as CapitalProductApplicationTransitionReason,
  Xf as CapitalProductApplicationType,
  Yf as CapitalProductHealthStatus,
  zf as CapitalProductInternalRepaymentPlanType,
  $f as CapitalProductOfferLabel,
  Hf as CapitalProductState,
  Vf as CapitalProductSuperApplicationActionType,
  Wf as CapitalProductType,
  Lf as CapitalProductType1,
  Qf as CardBusinessTableRowStatus,
  wf as CardDeserializerCardType,
  If as CardLifecycleStateSerializerLifecycleState,
  Uf as CardSerializer1FulfillmentStatus,
  Df as CardSerializer1State,
  Tf as CardSerializerCardType,
  Gf as CardSerializerFulfillmentStatus,
  Nf as CardSerializerState,
  Af as CardShippingMethod,
  Sf as CardTransactionType,
  vf as CardTransitionChannel,
  Ef as CardTransitionReasonCode,
  Cf as CardTransitionState,
  Bf as CardTransitionType,
  Rf as CashAdvanceChargeOffSerializerChargeOffType,
  Ff as ChargeOffInputSerializerChargeOffType,
  Mf as CountryCode,
  kf as CrmDeliveryAttemptState,
  xf as CrmEventType,
  bf as CrmVendor,
  Pf as Currency,
  Kf as Currency1,
  hf as CurrencyCode,
  Of as DataRequirement,
  ff as DatasetType,
  qf as DatasetUploadStatus,
  gf as DatashareMethod,
  lf as DayOfWeek,
  mf as DecisionStatus,
  _f as DeniedReason,
  pf as Direction,
  df as DiscountProgramFundingType,
  yf as DiscountProgramState,
  cf as DiscountProgramType,
  uf as DisputeState,
  af as DocumentUploadPurpose,
  of as DoordashAdminUserMxRole,
  sf as EditSource,
  rf as EmailBatchState,
  nf as EmailSubscriptionState,
  ef as EmailTriggerSource,
  tf as EntityType,
  Jq as FeeDiscountProgramAccessRequestState,
  Zq as FieldType,
  jq as FinancialRecordDocType,
  Xq as FutureActivityType,
  Yq as IdentityDataShare,
  zq as IncorporationJurisdiction,
  $q as IncorporationJurisdiction1,
  Hq as IncorporationType,
  Vq as IncorporationType1,
  Wq as InfoNeededStatus,
  Lq as IngestionStatus,
  Qq as InnerTransferType,
  wq as KycApplicationStatus,
  Iq as KycBusinessReportFieldType,
  Tq as KycIndividualReportFieldType,
  Gq as LienReviewState,
  Nq as LienStatus,
  Uq as LienType,
  Dq as LoanBusinessTableRowStatus,
  Aq as ManualReviewReason,
  Sq as MarketingEmailType,
  vq as McaBusinessTableRowStatus,
  Eq as MerchantBankAccountType,
  Cq as MerchantBankApplicationState,
  Bq as MerchantBankCardDisputeReason,
  Rq as MerchantBankCardDisputeState,
  Fq as MerchantBankCardPaymentPreference,
  Mq as MerchantBankCardReplacementReason,
  kq as MerchantBankCardType,
  xq as MerchantBankExternalAccountState,
  bq as MerchantBankExternalBankAccountType,
  Pq as MerchantBankOfferProductType,
  Kq as MerchantBankReceivedTransferType,
  hq as MerchantBankRecipientPaymentType,
  Oq as MerchantBankRepaymentType,
  fq as MerchantBankTransactionDestinationAccountType,
  qq as MerchantBankTransactionPaymentDirection,
  gq as MerchantBankTransactionState,
  lq as MerchantBankTransactionType,
  mq as MerchantBankTransferPaymentType,
  _q as MicroDepositVerificationState,
  pq as MinimumRepaymentAmountDueReason,
  dq as NetSettlementState,
  yq as NoCodePilotStatus,
  cq as NotificationType,
  iq as OnboardingAction,
  uq as OnboardingProductType,
  aq as OnboardingState,
  oq as OneOffActionType,
  sq as OptInState,
  rq as OrderStatus,
  nq as ParafinProduct,
  eq as PartnerEventType,
  tq as PersonaInquiryType,
  Jg as PhoneNumberCountryCode,
  Zg as PhoneNumberType,
  jg as PlaidInvestigationDeniedReason,
  Xg as PlaidLinkageWithInvestigationSerializerState,
  Yg as RefundFailureReason,
  zg as RefundStatus,
  $g as RepaymentDetailsRepaymentPlanType,
  Hg as RepaymentDisbursementType,
  Vg as RepaymentPauseSerializerPauseType,
  Wg as RepaymentSchedule,
  Lg as RepaymentSerializer1RepaymentType,
  Qg as RepaymentSerializer1State,
  wg as RepaymentsPatchDeserializerState,
  Ig as RepaymentsPostDeserializerRepaymentType,
  Tg as RepaymentsPostDeserializerState,
  Gg as ReplacementDetailsReplacementReason,
  Ng as RestrictionExternalReason,
  Ug as RestrictionReason,
  Dg as RestrictionSource,
  Ag as RestrictionType,
  Sg as ReturnCode,
  vg as RevenueShareAccountType,
  Eg as RevenueShareBasisType,
  Cg as ReversalReason,
  Bg as RutterConnectionState,
  Rg as RutterIntegrationType,
  Fg as RutterPlatform,
  Mg as S3PartnerDashboardCsvStatus,
  kg as S3PartnerDashboardCsvTableType,
  xg as SalesDataShare,
  bg as SardineCustomerScoreState,
  Pg as SardineFlowName,
  Kg as SardineLevel,
  hg as Schedule,
  Og as SessionType,
  fg as State,
  qg as StripeSaleRecordIngestionType,
  gg as TermsOfServiceLinkType,
  lg as Timezone,
  mg as UnderwriteIntroductoryOfferAttemptFailureReason,
  _g as UnderwriteIntroductoryOfferAttemptStatus,
  pg as UnderwritingDesiredOutputState,
  dg as UnifiedRealtimeUnderwritingAttemptFailureReason,
  yg as UnifiedRealtimeUnderwritingAttemptStatus,
  cg as UserRole,
  ig as ValidationStatus,
  ug as VerificationStatus,
  ag as WebNotificationState,
  og as WebSubscriptionState,
  sg as WebhookDeliveryAttemptState,
  rg as WebhookEndpointStatus,
  ng as WebhookEventDeliveryState,
  eg as WebhookEventMode,
  tg as WebhookEventType,
  vd as attemptSync,
  Gy as batchCreatePdPartnerEventMarkAllNotificationsRead,
  Od as batchResendWebhookAttempt,
  Jr as benchmarkGetCapitalProductOwnership,
  ny as countPdBusinessTableRows,
  my as countPdCapitalProductApplicationRow,
  hy as countPdCapitalProductOfferRow,
  iy as countPdCapitalProductRow,
  Yl as createAttemptToUnderwriteBnplOrder,
  Km as createAttemptToUnderwriteIntroductoryOffers,
  Is as createBankAccountInfo,
  ys as createCapitalProductIntents,
  Os as createCapitalProductSuperApplicationActions,
  qs as createCapitalProductSuperApplications,
  kr as createCapitalProducts,
  os as createOfferedCapitalProductFeeDiscount,
  lt as createOrganization,
  yt as createPartnerApiKey,
  yp as createPdFeeDiscountProgram,
  op as createPdFeeDiscountProgramAccessRequest,
  Iy as createPdPartnerEventMarkNotificationRead,
  Qd as createPdWebhookEndpoint,
  Zs as createPlaidInvestigation,
  _c as createS3OfferCsv,
  tp as createS3PartnerDashboardTableCsv,
  im as deleteAuthManagementUseridSamlSamlconfigid,
  Ye as deleteBeneficialOwnersId,
  kn as deleteBusinessOfficersId,
  w as deleteCashAdvancePausesP1,
  Ue as deleteMarketingIntegrationsVendor,
  Ki as deleteMerchantBankExternalAccountsId,
  fu as deleteMerchantBankRecipientsId,
  Gt as deleteOrganizationDeleteUsers,
  Ut as deletePartnerDeleteUsers,
  Jt as deletePartnerSyncWebhooksId,
  Wd as deletePdWebhookEndpoint,
  Ft as deleteSlackChannelsId,
  rd as experimentalCapitalProductOfferClosedSandbox,
  ed as experimentalCapitalProductOfferCreatedSandbox,
  Hi as getAccountingInfo,
  jp as getAmazonFundingAccountLock,
  sc as getAnalyticsCubeToken,
  Sd as getAttemptSyncMutationOptions,
  rm as getAuthManagementP1Saml,
  Ns as getBankAccountInfos,
  sn as getBanks,
  Ty as getBatchCreatePdPartnerEventMarkAllNotificationsReadMutationOptions,
  hd as getBatchResendWebhookAttemptMutationOptions,
  ts as getBenchmarkGetCapitalProductOwnershipQueryKey,
  es as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  We as getBeneficialOwners,
  b as getBusinessAnnotations,
  f as getBusinessCores,
  mo as getBusinessIdentities,
  hn as getBusinessOfficers,
  qn as getBusinessTableRowsCard,
  mn as getBusinessTableRowsLoan,
  dn as getBusinessTableRowsMca,
  un as getBusinessTableRowsProductAgnostic,
  Ka as getBusinessesDailySalesRecords,
  kc as getBusinessesNotifications,
  $o as getBusinessesProducts,
  vl as getBusinessesShowTopUpOffer,
  Ks as getCalculatedChunkValues,
  Ar as getCapitalProductActivities,
  Qr as getCapitalProductApplicationSnapshots,
  Nr as getCapitalProductApplications,
  us as getCapitalProductIntents,
  $r as getCapitalProductNetTopUpItemization,
  Br as getCapitalProductOfferCollection,
  Or as getCapitalProductProspectiveTermsOfService,
  ns as getCapitalProductRepaymentSchedule,
  Xr as getCapitalProductStateDisclosure,
  Pr as getCapitalProducts,
  Em as getCardAccessToken,
  bm as getCardBalances,
  Am as getCardLifecycleStates,
  Im as getCardPendingTransactions,
  Nm as getCardTransactions,
  Mm as getCardholders,
  Qo as getCards,
  R as getCashAdvanceChargeOffs,
  U as getCashAdvancePauses,
  ry as getCountPdBusinessTableRowsQueryKey,
  sy as getCountPdBusinessTableRowsQueryOptions,
  ly as getCountPdCapitalProductApplicationRowQueryKey,
  gy as getCountPdCapitalProductApplicationRowQueryOptions,
  Ky as getCountPdCapitalProductOfferRowQueryKey,
  Py as getCountPdCapitalProductOfferRowQueryOptions,
  cy as getCountPdCapitalProductRowQueryKey,
  yy as getCountPdCapitalProductRowQueryOptions,
  ms as getCounteroffer,
  Xl as getCreateAttemptToUnderwriteBnplOrderMutationOptions,
  Pm as getCreateAttemptToUnderwriteIntroductoryOffersMutationOptions,
  ws as getCreateBankAccountInfoMutationOptions,
  ds as getCreateCapitalProductIntentsMutationOptions,
  hs as getCreateCapitalProductSuperApplicationActionsMutationOptions,
  fs as getCreateCapitalProductSuperApplicationsMutationOptions,
  Mr as getCreateCapitalProductsMutationOptions,
  as as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  gt as getCreateOrganizationMutationOptions,
  dt as getCreatePartnerApiKeyMutationOptions,
  ap as getCreatePdFeeDiscountProgramAccessRequestMutationOptions,
  dp as getCreatePdFeeDiscountProgramMutationOptions,
  wy as getCreatePdPartnerEventMarkNotificationReadMutationOptions,
  Ld as getCreatePdWebhookEndpointMutationOptions,
  Js as getCreatePlaidInvestigationMutationOptions,
  mc as getCreateS3OfferCsvMutationOptions,
  ep as getCreateS3PartnerDashboardTableCsvMutationOptions,
  Jn as getDataingestionS3DatasetIngestionConfigs,
  Ln as getDataingestionS3DatasetIntegrations,
  In as getDataingestionS3DatasetUploadValidationResults,
  Nn as getDataingestionS3DatasetUploads,
  zn as getDataingestionS3OrgDatasetIntegrations,
  cr as getDataingestionStripeIngestions,
  Ui as getDebtChecks,
  Bi as getDebts,
  cm as getDeleteAuthManagementUseridSamlSamlconfigidMutationOptions,
  Xe as getDeleteBeneficialOwnersIdMutationOptions,
  Mn as getDeleteBusinessOfficersIdMutationOptions,
  Q as getDeleteCashAdvancePausesP1MutationOptions,
  Ne as getDeleteMarketingIntegrationsVendorMutationOptions,
  Pi as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  Ou as getDeleteMerchantBankRecipientsIdMutationOptions,
  Tt as getDeleteOrganizationDeleteUsersMutationOptions,
  Nt as getDeletePartnerDeleteUsersMutationOptions,
  te as getDeletePartnerSyncWebhooksIdMutationOptions,
  Vd as getDeletePdWebhookEndpointMutationOptions,
  Rt as getDeleteSlackChannelsIdMutationOptions,
  Zo as getDocumentUploadGroup,
  ra as getDocumentUploadList,
  wl as getEarlyManualRepaymentWhitelist,
  sd as getExperimentalCapitalProductOfferClosedSandboxMutationOptions,
  nd as getExperimentalCapitalProductOfferCreatedSandboxMutationOptions,
  wi as getFinancialReviews,
  xs as getFlexLoanMinimumRepaymentSchedule,
  Ws as getFundingAccounts,
  Dl as getFundingPlaidLinkToken,
  z as getFutureActivities,
  $i as getGetAccountingInfoQueryKey,
  zi as getGetAccountingInfoQueryOptions,
  Zp as getGetAmazonFundingAccountLockQueryKey,
  Jp as getGetAmazonFundingAccountLockQueryOptions,
  oc as getGetAnalyticsCubeTokenQueryKey,
  ac as getGetAnalyticsCubeTokenQueryOptions,
  sm as getGetAuthManagementP1SamlQueryKey,
  om as getGetAuthManagementP1SamlQueryOptions,
  Gs as getGetBankAccountInfosQueryKey,
  Ts as getGetBankAccountInfosQueryOptions,
  on as getGetBanksQueryKey,
  an as getGetBanksQueryOptions,
  Ve as getGetBeneficialOwnersQueryKey,
  He as getGetBeneficialOwnersQueryOptions,
  x as getGetBusinessAnnotationsQueryKey,
  k as getGetBusinessAnnotationsQueryOptions,
  O as getGetBusinessCoresQueryKey,
  h as getGetBusinessCoresQueryOptions,
  lo as getGetBusinessIdentitiesQueryKey,
  go as getGetBusinessIdentitiesQueryOptions,
  Kn as getGetBusinessOfficersQueryKey,
  Pn as getGetBusinessOfficersQueryOptions,
  fn as getGetBusinessTableRowsCardQueryKey,
  On as getGetBusinessTableRowsCardQueryOptions,
  ln as getGetBusinessTableRowsLoanQueryKey,
  gn as getGetBusinessTableRowsLoanQueryOptions,
  pn as getGetBusinessTableRowsMcaQueryKey,
  _n as getGetBusinessTableRowsMcaQueryOptions,
  cn as getGetBusinessTableRowsProductAgnosticQueryKey,
  yn as getGetBusinessTableRowsProductAgnosticQueryOptions,
  Pa as getGetBusinessesDailySalesRecordsQueryKey,
  ba as getGetBusinessesDailySalesRecordsQueryOptions,
  Mc as getGetBusinessesNotificationsQueryKey,
  Fc as getGetBusinessesNotificationsQueryOptions,
  zo as getGetBusinessesProductsQueryKey,
  Yo as getGetBusinessesProductsQueryOptions,
  Sl as getGetBusinessesShowTopUpOfferQueryKey,
  Al as getGetBusinessesShowTopUpOfferQueryOptions,
  Ps as getGetCalculatedChunkValuesQueryKey,
  bs as getGetCalculatedChunkValuesQueryOptions,
  Dr as getGetCapitalProductActivitiesQueryKey,
  Ur as getGetCapitalProductActivitiesQueryOptions,
  Lr as getGetCapitalProductApplicationSnapshotsQueryKey,
  Wr as getGetCapitalProductApplicationSnapshotsQueryOptions,
  Gr as getGetCapitalProductApplicationsQueryKey,
  Tr as getGetCapitalProductApplicationsQueryOptions,
  is as getGetCapitalProductIntentsQueryKey,
  cs as getGetCapitalProductIntentsQueryOptions,
  zr as getGetCapitalProductNetTopUpItemizationQueryKey,
  Yr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  Cr as getGetCapitalProductOfferCollectionQueryKey,
  Er as getGetCapitalProductOfferCollectionQueryOptions,
  hr as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  Kr as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  rs as getGetCapitalProductRepaymentScheduleQueryKey,
  ss as getGetCapitalProductRepaymentScheduleQueryOptions,
  jr as getGetCapitalProductStateDisclosureQueryKey,
  Zr as getGetCapitalProductStateDisclosureQueryOptions,
  br as getGetCapitalProductsQueryKey,
  xr as getGetCapitalProductsQueryOptions,
  vm as getGetCardAccessTokenQueryKey,
  Sm as getGetCardAccessTokenQueryOptions,
  xm as getGetCardBalancesQueryKey,
  km as getGetCardBalancesQueryOptions,
  Dm as getGetCardLifecycleStatesQueryKey,
  Um as getGetCardLifecycleStatesQueryOptions,
  wm as getGetCardPendingTransactionsQueryKey,
  Qm as getGetCardPendingTransactionsQueryOptions,
  Gm as getGetCardTransactionsQueryKey,
  Tm as getGetCardTransactionsQueryOptions,
  Fm as getGetCardholdersQueryKey,
  Rm as getGetCardholdersQueryOptions,
  Lo as getGetCardsQueryKey,
  Wo as getGetCardsQueryOptions,
  B as getGetCashAdvanceChargeOffsQueryKey,
  C as getGetCashAdvanceChargeOffsQueryOptions,
  N as getGetCashAdvancePausesQueryKey,
  G as getGetCashAdvancePausesQueryOptions,
  ls as getGetCounterofferQueryKey,
  gs as getGetCounterofferQueryOptions,
  tr as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  er as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  Wn as getGetDataingestionS3DatasetIntegrationsQueryKey,
  Vn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  wn as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  Qn as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  Gn as getGetDataingestionS3DatasetUploadsQueryKey,
  Tn as getGetDataingestionS3DatasetUploadsQueryOptions,
  Yn as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  Xn as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  yr as getGetDataingestionStripeIngestionsQueryKey,
  dr as getGetDataingestionStripeIngestionsQueryOptions,
  Ni as getGetDebtChecksQueryKey,
  Gi as getGetDebtChecksQueryOptions,
  Ci as getGetDebtsQueryKey,
  Ei as getGetDebtsQueryOptions,
  Jo as getGetDocumentUploadGroupQueryKey,
  ta as getGetDocumentUploadGroupQueryOptions,
  sa as getGetDocumentUploadListQueryKey,
  oa as getGetDocumentUploadListQueryOptions,
  Ql as getGetEarlyManualRepaymentWhitelistQueryKey,
  Ll as getGetEarlyManualRepaymentWhitelistQueryOptions,
  Qi as getGetFinancialReviewsQueryKey,
  Li as getGetFinancialReviewsQueryOptions,
  ks as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  Ms as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  Vs as getGetFundingAccountsQueryKey,
  Hs as getGetFundingAccountsQueryOptions,
  Ul as getGetFundingPlaidLinkTokenQueryKey,
  Nl as getGetFundingPlaidLinkTokenQueryOptions,
  Y as getGetFutureActivitiesQueryKey,
  X as getGetFutureActivitiesQueryOptions,
  ye as getGetHackathonGetPilotQueryKey,
  de as getGetHackathonGetPilotQueryOptions,
  ro as getGetKycRetryFieldsQueryKey,
  so as getGetKycRetryFieldsQueryOptions,
  Om as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryKey,
  hm as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryOptions,
  la as getGetLiensQueryKey,
  ga as getGetLiensQueryOptions,
  Te as getGetMarketingGtmToolkitQueryKey,
  Ie as getGetMarketingGtmToolkitQueryOptions,
  ve as getGetMarketingIntegrationsQueryKey,
  Se as getGetMarketingIntegrationsQueryOptions,
  Qe as getGetMarketingParagonTokenQueryKey,
  Le as getGetMarketingParagonTokenQueryOptions,
  Ia as getGetMerchantBankAccountsIdBalancesQueryKey,
  wa as getGetMerchantBankAccountsIdBalancesQueryOptions,
  Na as getGetMerchantBankAccountsIdQueryKey,
  Ga as getGetMerchantBankAccountsIdQueryOptions,
  La as getGetMerchantBankAccountsQueryKey,
  Wa as getGetMerchantBankAccountsQueryOptions,
  Qp as getGetMerchantBankApplicationsIdQueryKey,
  Lp as getGetMerchantBankApplicationsIdQueryOptions,
  Vp as getGetMerchantBankApplicationsQueryKey,
  Hp as getGetMerchantBankApplicationsQueryOptions,
  wu as getGetMerchantBankCardAccessTokenQueryKey,
  Qu as getGetMerchantBankCardAccessTokenQueryOptions,
  Hu as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  $u as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  Uu as getGetMerchantBankCardCardholdersQueryKey,
  Nu as getGetMerchantBankCardCardholdersQueryOptions,
  Ju as getGetMerchantBankCardDisputesQueryKey,
  ti as getGetMerchantBankCardDisputesQueryOptions,
  Bu as getGetMerchantBankCardsQueryKey,
  Cu as getGetMerchantBankCardsQueryOptions,
  di as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  pi as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  Oi as getGetMerchantBankExternalAccountsIdQueryKey,
  hi as getGetMerchantBankExternalAccountsIdQueryOptions,
  ii as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  ci as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  mi as getGetMerchantBankExternalAccountsQueryKey,
  li as getGetMerchantBankExternalAccountsQueryOptions,
  iu as getGetMerchantBankInterestQueryKey,
  cu as getGetMerchantBankInterestQueryOptions,
  Q_ as getGetMerchantBankOffersQueryKey,
  L_ as getGetMerchantBankOffersQueryOptions,
  gu as getGetMerchantBankRecipientsIdQueryKey,
  qu as getGetMerchantBankRecipientsIdQueryOptions,
  du as getGetMerchantBankRecipientsQueryKey,
  pu as getGetMerchantBankRecipientsQueryOptions,
  Xa as getGetMerchantBankTransactionsIdQueryKey,
  ja as getGetMerchantBankTransactionsIdQueryOptions,
  Ja as getGetMerchantBankTransactionsQueryKey,
  tu as getGetMerchantBankTransactionsQueryOptions,
  nu as getGetMerchantBankTransferFeesQueryKey,
  ru as getGetMerchantBankTransferFeesQueryOptions,
  ou as getGetMerchantBankTransferLimitsQueryKey,
  au as getGetMerchantBankTransferLimitsQueryOptions,
  e_ as getGetNaicsQueryKey,
  n_ as getGetNaicsQueryOptions,
  tc as getGetOfficerSubsidiaryRecordsQueryKey,
  ec as getGetOfficerSubsidiaryRecordsQueryOptions,
  qr as getGetOnboardingBusinessRequirementsQueryKey,
  fr as getGetOnboardingBusinessRequirementsQueryOptions,
  po as getGetOnboardingInfoNeededQueryKey,
  _o as getGetOnboardingInfoNeededQueryOptions,
  io as getGetOnboardingStatesQueryKey,
  co as getGetOnboardingStatesQueryOptions,
  Xm as getGetOptInsQueryKey,
  jm as getGetOptInsQueryOptions,
  rl as getGetOptOutEligibilityQueryKey,
  sl as getGetOptOutEligibilityQueryOptions,
  Wm as getGetOrderQueryKey,
  Vm as getGetOrderQueryOptions,
  ht as getGetOrganizationQueryKey,
  Kt as getGetOrganizationQueryOptions,
  Jy as getGetOwnerAuthQueryKey,
  td as getGetOwnerAuthQueryOptions,
  tn as getGetOwnerIsBeneficialOwnerQueryKey,
  en as getGetOwnerIsBeneficialOwnerQueryOptions,
  ho as getGetOwnersQueryKey,
  Ko as getGetOwnersQueryOptions,
  i_ as getGetPADAgreementQueryKey,
  c_ as getGetPADAgreementQueryOptions,
  Ra as getGetParafinderBusinessTypesQueryKey,
  Ba as getGetParafinderBusinessTypesQueryOptions,
  Aa as getGetParafinderCapitalInfoQueryKey,
  Da as getGetParafinderCapitalInfoQueryOptions,
  ka as getGetParafinderQueryKey,
  Ma as getGetParafinderQueryOptions,
  Ea as getGetParafinderSummaryQueryKey,
  va as getGetParafinderSummaryQueryOptions,
  it as getGetPartnerApiKeyQueryKey,
  ct as getGetPartnerApiKeyQueryOptions,
  Wt as getGetPartnerConfigsQueryKey,
  Vt as getGetPartnerConfigsQueryOptions,
  wt as getGetPartnerPublicConfigsQueryKey,
  Qt as getGetPartnerPublicConfigsQueryOptions,
  Yt as getGetPartnerSyncWebhooksQueryKey,
  Xt as getGetPartnerSyncWebhooksQueryOptions,
  Ct as getGetPartnerUsersQueryKey,
  Et as getGetPartnerUsersQueryOptions,
  Np as getGetPartnerWidgetConfigsQueryKey,
  Gp as getGetPartnerWidgetConfigsQueryOptions,
  A as getGetPartnersDoordashAdminUsersQueryKey,
  D as getGetPartnersDoordashAdminUsersQueryOptions,
  Z as getGetPartnersQueryKey,
  J as getGetPartnersQueryOptions,
  rt as getGetPartnersUnderwritingQueryKey,
  st as getGetPartnersUnderwritingQueryOptions,
  rp as getGetPdFeeDiscountProgramAccessRequestQueryKey,
  sp as getGetPdFeeDiscountProgramAccessRequestQueryOptions,
  _p as getGetPdFeeDiscountProgramQueryKey,
  mp as getGetPdFeeDiscountProgramQueryOptions,
  Cy as getGetPdOfferUrlQueryKey,
  Ey as getGetPdOfferUrlQueryOptions,
  Op as getGetPdRevenueShareQueryKey,
  hp as getGetPdRevenueShareQueryOptions,
  Xy as getGetPdUtilityInfoQueryKey,
  jy as getGetPdUtilityInfoQueryOptions,
  Xs as getGetPlaidInvestigationsQueryKey,
  js as getGetPlaidInvestigationsQueryOptions,
  $m as getGetRefundQueryKey,
  zm as getGetRefundQueryOptions,
  H as getGetRepaymentPlanParametersQueryKey,
  $ as getGetRepaymentPlanParametersQueryOptions,
  Cl as getGetRepaymentsQueryKey,
  El as getGetRepaymentsQueryOptions,
  ua as getGetRestrictionQueryKey,
  ia as getGetRestrictionQueryOptions,
  pa as getGetRestrictionsDetailsQueryKey,
  _a as getGetRestrictionsDetailsQueryOptions,
  Cs as getGetRunOfacScreeningResultsQueryKey,
  Es as getGetRunOfacScreeningResultsQueryOptions,
  Fp as getGetRutterConnectionOptionsQueryKey,
  Rp as getGetRutterConnectionOptionsQueryOptions,
  Pp as getGetRutterConnectionsQueryKey,
  bp as getGetRutterConnectionsQueryOptions,
  Yd as getGetS3PartnerDashboardTableCsvQueryKey,
  Xd as getGetS3PartnerDashboardTableCsvQueryOptions,
  vp as getGetSardineSessionIdQueryKey,
  Sp as getGetSardineSessionIdQueryOptions,
  kt as getGetSlackChannelsQueryKey,
  Mt as getGetSlackChannelsQueryOptions,
  Tl as getGetStatementsQueryKey,
  Il as getGetStatementsQueryOptions,
  Fe as getGetStatementsV2QueryKey,
  Re as getGetStatementsV2QueryOptions,
  Gd as getGetSyncAndMaybeMarkFailedMutationOptions,
  Dd as getGetSyncQueryKey,
  Ud as getGetSyncQueryOptions,
  h_ as getGetTemplateMockQueryKey,
  K_ as getGetTemplateMockQueryOptions,
  M_ as getGetTemplateSnapshotQueryKey,
  F_ as getGetTemplateSnapshotQueryOptions,
  b_ as getGetTemplateSnapshotsQueryKey,
  x_ as getGetTemplateSnapshotsQueryOptions,
  B_ as getGetTemplateTypesQueryKey,
  C_ as getGetTemplateTypesQueryOptions,
  __ as getGetTemplatesQueryKey,
  m_ as getGetTemplatesQueryOptions,
  Ds as getGetTermLoanProspectiveRepaymentsQueryKey,
  Us as getGetTermLoanProspectiveRepaymentsQueryOptions,
  $l as getGetUnderwritingStatusForOrderQueryKey,
  zl as getGetUnderwritingStatusForOrderQueryOptions,
  ce as getHackathonGetPilot,
  no as getKycRetryFields,
  fm as getLatestAttemptToUnderwriteIntroductoryOffers,
  ma as getLiens,
  ft as getLinkPartnerMutationOptions,
  ic as getListApiRequestsQueryKey,
  cc as getListApiRequestsQueryOptions,
  vn as getListDatasetUploadsQueryKey,
  Sn as getListDatasetUploadsQueryOptions,
  Dn as getListManualDatasetUploadValidationResultsQueryKey,
  Un as getListManualDatasetUploadValidationResultsQueryOptions,
  _t as getListOrganizationsQueryKey,
  mt as getListOrganizationsQueryOptions,
  Kc as getListPartnerScheduledMarketingEmailConfigsQueryKey,
  Pc as getListPartnerScheduledMarketingEmailConfigsQueryOptions,
  Sc as getListPdBusinessDetailsApplicationsQueryKey,
  Ac as getListPdBusinessDetailsApplicationsQueryOptions,
  Uc as getListPdBusinessDetailsBankInfoQueryKey,
  Nc as getListPdBusinessDetailsBankInfoQueryOptions,
  Tc as getListPdBusinessDetailsBusinessInfoQueryKey,
  Ic as getListPdBusinessDetailsBusinessInfoQueryOptions,
  Vc as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  Hc as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  zc as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  Yc as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  Qc as getListPdBusinessDetailsFundedProductsQueryKey,
  Lc as getListPdBusinessDetailsFundedProductsQueryOptions,
  jc as getListPdBusinessDetailsOffersQueryKey,
  Zc as getListPdBusinessDetailsOffersQueryOptions,
  ty as getListPdBusinessDetailsOwnerInfoQueryKey,
  ey as getListPdBusinessDetailsOwnerInfoQueryOptions,
  ay as getListPdBusinessTableRowsQueryKey,
  uy as getListPdBusinessTableRowsQueryOptions,
  fy as getListPdCapitalProductApplicationRowsQueryKey,
  Oy as getListPdCapitalProductApplicationRowsQueryOptions,
  xy as getListPdCapitalProductOfferRowsQueryKey,
  ky as getListPdCapitalProductOfferRowsQueryOptions,
  py as getListPdCapitalProductRowsQueryKey,
  _y as getListPdCapitalProductRowsQueryOptions,
  kd as getListPdCrmDeliveryAttemptsQueryKey,
  Md as getListPdCrmDeliveryAttemptsQueryOptions,
  Cd as getListPdCrmEventInfoQueryKey,
  Ed as getListPdCrmEventInfoQueryOptions,
  Pd as getListPdCrmEventsQueryKey,
  bd as getListPdCrmEventsQueryOptions,
  $y as getListPdCrmIntegrationsQueryKey,
  zy as getListPdCrmIntegrationsQueryOptions,
  gp as getListPdFeeDiscountProgramCohortTableRowsQueryKey,
  qp as getListPdFeeDiscountProgramCohortTableRowsQueryOptions,
  ip as getListPdFeeDiscountProgramQueryKey,
  cp as getListPdFeeDiscountProgramQueryOptions,
  Sy as getListPdPartnerEventListUserSubscriptionsQueryKey,
  Ay as getListPdPartnerEventListUserSubscriptionsQueryOptions,
  Uy as getListPdPartnerEventListWebNotificationsQueryKey,
  Ny as getListPdPartnerEventListWebNotificationsQueryOptions,
  Fy as getListPdSupportInfoQueryKey,
  Ry as getListPdSupportInfoQueryOptions,
  Id as getListPdWebhookEndpointsQueryKey,
  wd as getListPdWebhookEndpointsQueryOptions,
  pd as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  _d as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  cd as getListPdWebhookLogsEventInfoQueryKey,
  yd as getListPdWebhookLogsEventInfoQueryOptions,
  ad as getListPdWebhookLogsEventsQueryKey,
  ud as getListPdWebhookLogsEventsQueryOptions,
  dc as getListS3OfferCsvsQueryKey,
  pc as getListS3OfferCsvsQueryOptions,
  Zd as getListS3PartnerDashboardTableCsvsQueryKey,
  Jd as getListS3PartnerDashboardTableCsvsQueryOptions,
  $_ as getListSardineDetailsQueryKey,
  z_ as getListSardineDetailsQueryOptions,
  gc as getListSendMarketingEmailBatchesQueryKey,
  qc as getListSendMarketingEmailBatchesQueryOptions,
  ur as getListVersionedSchemasQueryKey,
  ir as getListVersionedSchemasQueryOptions,
  d_ as getMarkPADAgreementSignedMutationOptions,
  Ge as getMarketingGtmToolkit,
  Ee as getMarketingIntegrations,
  we as getMarketingParagonToken,
  Qa as getMerchantBankAccounts,
  Ua as getMerchantBankAccountsId,
  Ta as getMerchantBankAccountsIdBalances,
  Wp as getMerchantBankApplications,
  wp as getMerchantBankApplicationsId,
  Iu as getMerchantBankCardAccessToken,
  Vu as getMerchantBankCardCardholderAccessToken,
  Du as getMerchantBankCardCardholders,
  Zu as getMerchantBankCardDisputes,
  Ru as getMerchantBankCards,
  _i as getMerchantBankExternalAccounts,
  fi as getMerchantBankExternalAccountsId,
  yi as getMerchantBankExternalAccountsIdPlaidLinkToken,
  ui as getMerchantBankExternalAccountsPlaidLinkToken,
  uu as getMerchantBankInterest,
  w_ as getMerchantBankOffers,
  yu as getMerchantBankRecipients,
  lu as getMerchantBankRecipientsId,
  Za as getMerchantBankTransactions,
  Ya as getMerchantBankTransactionsId,
  eu as getMerchantBankTransferFees,
  su as getMerchantBankTransferLimits,
  ld as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  gd as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  t_ as getNaics,
  Ji as getOfficerSubsidiaryRecords,
  gr as getOnboardingBusinessRequirements,
  yo as getOnboardingInfoNeeded,
  uo as getOnboardingStates,
  Ym as getOptIns,
  nl as getOptOutEligibility,
  Lm as getOrder,
  Ot as getOrganization,
  Zy as getOwnerAuth,
  Je as getOwnerIsBeneficialOwner,
  Oo as getOwners,
  u_ as getPADAgreement,
  xa as getParafinder,
  Fa as getParafinderBusinessTypes,
  Sa as getParafinderCapitalInfo,
  Ca as getParafinderSummary,
  ut as getPartnerApiKey,
  Lt as getPartnerConfigs,
  It as getPartnerPublicConfigs,
  xc as getPartnerScheduledMarketingEmailConfigMutationOptions,
  zt as getPartnerSyncWebhooks,
  Bt as getPartnerUsers,
  Up as getPartnerWidgetConfigs,
  j as getPartners,
  S as getPartnersDoordashAdminUsers,
  nt as getPartnersUnderwriting,
  dm as getPatchAuthManagementUseridSamlSamlconfigidMutationOptions,
  Ze as getPatchBeneficialOwnersIdMutationOptions,
  fo as getPatchBusinessIdentitiesIdMutationOptions,
  Rn as getPatchBusinessOfficersIdMutationOptions,
  _s as getPatchCapitalProductIntentsMutationOptions,
  Rr as getPatchCapitalProductsMutationOptions,
  Cm as getPatchCardholdersCardholderidMutationOptions,
  W as getPatchCashAdvancePausesP1MutationOptions,
  Ss as getPatchClearWatchlistHitsIdMutationOptions,
  lr as getPatchDataingestionStripeIngestionsMutationOptions,
  Do as getPatchFlexLoanAgeMutationOptions,
  To as getPatchFlexLoanFundMutationOptions,
  No as getPatchFlexLoanOwnershipMutationOptions,
  fa as getPatchLiensIdMutationOptions,
  Au as getPatchMerchantBankCardCardholdersIdMutationOptions,
  xi as getPatchMerchantBankExternalAccountsIdMutationOptions,
  Ri as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  X_ as getPatchMerchantBankMerchantConfigMutationOptions,
  Fu as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  Ku as getPatchMerchantBankRecipientsIdMutationOptions,
  I_ as getPatchMfaExtendSessionMutationOptions,
  rn as getPatchOwnerIsBeneficialOwnerMutationOptions,
  bo as getPatchOwnersIdMutationOptions,
  $t as getPatchPartnerConfigsIdMutationOptions,
  ne as getPatchPartnerSyncWebhooksIdMutationOptions,
  Dt as getPatchPartnerUsersMutationOptions,
  Ip as getPatchPartnerWidgetConfigsMutationOptions,
  at as getPatchPartnersIdMutationOptions,
  eo as getPatchPersonaFallbackInquiryMutationOptions,
  Vl as getPatchRootMutationOptions,
  yl as getPatchSandboxCapitalProductFundMutationOptions,
  pl as getPatchSandboxCapitalProductFundingChecklistMutationOptions,
  ml as getPatchSandboxCapitalProductTopUpMutationOptions,
  xl as getPatchSandboxRepaymentsMutationOptions,
  f_ as getPatchTemplatesMutationOptions,
  pp as getPdFeeDiscountProgram,
  np as getPdFeeDiscountProgramAccessRequest,
  By as getPdOfferUrl,
  fp as getPdRevenueShare,
  Yy as getPdUtilityInfo,
  Ys as getPlaidInvestigations,
  Xi as getPostAccountingInfoMutationOptions,
  Bo as getPostAmazonRedirectUrlMutationOptions,
  qm as getPostAuthEmailOtpStartMutationOptions,
  lm as getPostAuthEmailOtpV2VerifyMutationOptions,
  _m as getPostAuthEmailOtpVerifyMutationOptions,
  tm as getPostAuthLoginStartMutationOptions,
  Z_ as getPostAuthLoginValidateOtpMutationOptions,
  um as getPostAuthManagementUseridSamlMutationOptions,
  nm as getPostAuthTokenExchangeMutationOptions,
  se as getPostAuthTokensMutationOptions,
  ze as getPostBeneficialOwnersMutationOptions,
  el as getPostBulkOptInMutationOptions,
  F as getPostBusinessAnnotationsMutationOptions,
  jo as getPostBusinessAuthLinkMutationOptions,
  P as getPostBusinessCoresMutationOptions,
  ko as getPostBusinessMutationOptions,
  xn as getPostBusinessOfficersMutationOptions,
  Bc as getPostBusinessesNotificationsMutationOptions,
  wr as getPostCapitalProductApplicationMutationOptions,
  So as getPostCapitalProductBusinessMutationOptions,
  Hr as getPostCapitalProductNetTopUpMutationOptions,
  Sr as getPostCapitalProductOfferCollectionMutationOptions,
  Ii as getPostCardCloseAccountMutationOptions,
  Ho as getPostCardsMutationOptions,
  v as getPostCashAdvanceChargeOffsMutationOptions,
  I as getPostCashAdvancePausesMutationOptions,
  Cn as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  $n as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  Zn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  or as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  _r as getPostDataingestionStripeIngestionsMutationOptions,
  Di as getPostDebtsCheckCompleteMutationOptions,
  Si as getPostDebtsMutationOptions,
  na as getPostDocumentUploadGroupMutationOptions,
  Vi as getPostFinancialReviewsMutationOptions,
  U_ as getPostForgiveCapitalFeeMutationOptions,
  zs as getPostFundingAccountsMutationOptions,
  le as getPostHackathonAwaitParafinRepresentativeMutationOptions,
  ie as getPostHackathonCreatePilotMutationOptions,
  _e as getPostHackathonGenerateOffersMutationOptions,
  Oe as getPostHackathonGmvMutationOptions,
  be as getPostHackathonMapOffersToEmailsMutationOptions,
  ae as getPostHackathonPartnersMutationOptions,
  Ke as getPostHackathonResetMutationOptions,
  qe as getPostHackathonSendOffersMutationOptions,
  ke as getPostHackathonUploadSalesCsvMutationOptions,
  ao as getPostKycRetryMutationOptions,
  A_ as getPostManualRepaymentMutationOptions,
  Cp as getPostMarkAccountingIntegrationSeenMutationOptions,
  De as getPostMarketingIntegrationsMutationOptions,
  Ce as getPostMarketingReviewMutationOptions,
  Ha as getPostMerchantBankAccountsMutationOptions,
  zp as getPostMerchantBankApplicationsMutationOptions,
  Fo as getPostMerchantBankBusinessMutationOptions,
  Tu as getPostMerchantBankCardActivateMutationOptions,
  Wu as getPostMerchantBankCardApplePayMutationOptions,
  ai as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  si as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  ni as getPostMerchantBankCardDisputesMutationOptions,
  ju as getPostMerchantBankCardGooglePayMutationOptions,
  Yu as getPostMerchantBankCardStateTransitionMutationOptions,
  vu as getPostMerchantBankCardsMutationOptions,
  qi as getPostMerchantBankExternalAccountsMutationOptions,
  Mi as getPostMerchantBankExternalBankTransfersMutationOptions,
  za as getPostMerchantBankInnerTransfersMutationOptions,
  V_ as getPostMerchantBankOffersMutationOptions,
  ku as getPostMerchantBankRecipientTransfersMutationOptions,
  bu as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  mu as getPostMerchantBankRecipientsMutationOptions,
  s_ as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  a_ as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  G_ as getPostMfaVerifyAccountDetailsMutationOptions,
  rc as getPostOfficerSubsidiaryRecordsMutationOptions,
  ha as getPostOptInMutationOptions,
  Jm as getPostOptInsMutationOptions,
  al as getPostOptOutMutationOptions,
  Zt as getPostPartnerSyncWebhooksMutationOptions,
  St as getPostPartnerUsersMutationOptions,
  et as getPostPartnersMutationOptions,
  ya as getPostRestrictionMutationOptions,
  kp as getPostRutterConnectionsMutationOptions,
  fl as getPostSandboxAmazonLockBankAccountMutationOptions,
  hl as getPostSandboxBreakBankAccountMutationOptions,
  gl as getPostSandboxCapitalProductPayOffMutationOptions,
  Ml as getPostSandboxMerchantBankSetupBusinessMutationOptions,
  Pl as getPostSandboxRepaymentsMutationOptions,
  Dp as getPostSardineSessionIdMutationOptions,
  wo as getPostSetupAmazonTopUpMutationOptions,
  bt as getPostSlackAuthorizeMutationOptions,
  Eo as getPostSpdMutationOptions,
  Zi as getPostTaxRecordsMutationOptions,
  v_ as getPostTemplateTypesMutationOptions,
  g_ as getPostTemplatesMutationOptions,
  Ec as getPostTermsOfServiceMarkShownMutationOptions,
  Rs as getPostTriggerRunCelticsChecklistMutationOptions,
  Xp as getPostWebhooksPlaidGeneralMutationOptions,
  rr as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  il as getPutSandboxCapitalProductTestOfferMutationOptions,
  Rl as getPutSandboxFeeDiscountMutationOptions,
  Hm as getRefund,
  V as getRepaymentPlanParameters,
  Bl as getRepayments,
  Rd as getResendCrmAttemptMutationOptions,
  fd as getResendWebhookAttemptMutationOptions,
  aa as getRestriction,
  da as getRestrictionsDetails,
  Bs as getRunOfacScreeningResults,
  Mp as getRutterConnectionOptions,
  Kp as getRutterConnections,
  zd as getS3PartnerDashboardTableCsv,
  Ep as getSardineSessionId,
  Oc as getSendMarketingEmailsMutationOptions,
  xt as getSlackChannels,
  Gl as getStatements,
  Me as getStatementsV2,
  Ad as getSync,
  Nd as getSyncAndMaybeMarkFailed,
  O_ as getTemplateMock,
  k_ as getTemplateSnapshot,
  P_ as getTemplateSnapshots,
  R_ as getTemplateTypes,
  p_ as getTemplates,
  As as getTermLoanProspectiveRepayments,
  Hl as getUnderwritingStatusForOrder,
  Ls as getUpdateBankAccountInfoMutationOptions,
  Ly as getUpdatePdPartnerEventUpdateUserSubscriptionMutationOptions,
  Vy as getUpdatePdPartnerEventUpdateUserSubscriptionsForEventTypeMutationOptions,
  $d as getUpdatePdWebhookEndpointMutationOptions,
  Jl as kitchen,
  qt as linkPartner,
  uc as listApiRequests,
  En as listDatasetUploads,
  An as listManualDatasetUploadValidationResults,
  pt as listOrganizations,
  hc as listPartnerScheduledMarketingEmailConfigs,
  vc as listPdBusinessDetailsApplications,
  Dc as listPdBusinessDetailsBankInfo,
  Gc as listPdBusinessDetailsBusinessInfo,
  Wc as listPdBusinessDetailsFundedProductDetails,
  $c as listPdBusinessDetailsFundedProductPaymentProgress,
  wc as listPdBusinessDetailsFundedProducts,
  Xc as listPdBusinessDetailsOffers,
  Jc as listPdBusinessDetailsOwnerInfo,
  oy as listPdBusinessTableRows,
  qy as listPdCapitalProductApplicationRows,
  by as listPdCapitalProductOfferRows,
  dy as listPdCapitalProductRows,
  xd as listPdCrmDeliveryAttempts,
  Bd as listPdCrmEventInfo,
  Kd as listPdCrmEvents,
  Hy as listPdCrmIntegrations,
  up as listPdFeeDiscountProgram,
  lp as listPdFeeDiscountProgramCohortTableRows,
  vy as listPdPartnerEventListUserSubscriptions,
  Dy as listPdPartnerEventListWebNotifications,
  My as listPdSupportInfo,
  Td as listPdWebhookEndpoints,
  dd as listPdWebhookLogsDeliveryAttempts,
  id as listPdWebhookLogsEventInfo,
  od as listPdWebhookLogsEvents,
  yc as listS3OfferCsvs,
  jd as listS3PartnerDashboardTableCsvs,
  H_ as listSardineDetails,
  lc as listSendMarketingEmailBatches,
  ar as listVersionedSchemas,
  y_ as markPADAgreementSigned,
  md as multiWebhookPdWebhookLogsDeliveryAttempts,
  bc as partnerScheduledMarketingEmailConfig,
  ym as patchAuthManagementUseridSamlSamlconfigid,
  je as patchBeneficialOwnersId,
  qo as patchBusinessIdentitiesId,
  Fn as patchBusinessOfficersId,
  ps as patchCapitalProductIntents,
  Fr as patchCapitalProducts,
  Bm as patchCardholdersCardholderid,
  L as patchCashAdvancePausesP1,
  vs as patchClearWatchlistHitsId,
  mr as patchDataingestionStripeIngestions,
  Ao as patchFlexLoanAge,
  Go as patchFlexLoanFund,
  Uo as patchFlexLoanOwnership,
  qa as patchLiensId,
  Su as patchMerchantBankCardCardholdersId,
  bi as patchMerchantBankExternalAccountsId,
  Fi as patchMerchantBankExternalBankTransfersId,
  Y_ as patchMerchantBankMerchantConfig,
  Mu as patchMerchantBankRecipientTransfersId,
  hu as patchMerchantBankRecipientsId,
  T_ as patchMfaExtendSession,
  nn as patchOwnerIsBeneficialOwner,
  Po as patchOwnersId,
  Ht as patchPartnerConfigsId,
  ee as patchPartnerSyncWebhooksId,
  At as patchPartnerUsers,
  Tp as patchPartnerWidgetConfigs,
  ot as patchPartnersId,
  to as patchPersonaFallbackInquiry,
  Wl as patchRoot,
  cl as patchSandboxCapitalProductFund,
  dl as patchSandboxCapitalProductFundingChecklist,
  _l as patchSandboxCapitalProductTopUp,
  bl as patchSandboxRepayments,
  q_ as patchTemplates,
  Yi as postAccountingInfo,
  Ro as postAmazonRedirectUrl,
  gm as postAuthEmailOtpStart,
  mm as postAuthEmailOtpV2Verify,
  pm as postAuthEmailOtpVerify,
  J_ as postAuthLoginStart,
  j_ as postAuthLoginValidateOtp,
  am as postAuthManagementUseridSaml,
  em as postAuthTokenExchange,
  re as postAuthTokens,
  $e as postBeneficialOwners,
  tl as postBulkOptIn,
  xo as postBusiness,
  M as postBusinessAnnotations,
  Xo as postBusinessAuthLink,
  K as postBusinessCores,
  bn as postBusinessOfficers,
  Rc as postBusinessesNotifications,
  Ir as postCapitalProductApplication,
  vo as postCapitalProductBusiness,
  Vr as postCapitalProductNetTopUp,
  vr as postCapitalProductOfferCollection,
  Ti as postCardCloseAccount,
  Vo as postCards,
  E as postCashAdvanceChargeOffs,
  T as postCashAdvancePauses,
  Bn as postDataingestionOneschemaGenerateJwt,
  Hn as postDataingestionS3DatasetIntegrations,
  jn as postDataingestionS3OrgDatasetIntegrations,
  sr as postDataingestionS3RunDatasetIngestion,
  pr as postDataingestionStripeIngestions,
  vi as postDebts,
  Ai as postDebtsCheckComplete,
  ea as postDocumentUploadGroup,
  Wi as postFinancialReviews,
  D_ as postForgiveCapitalFee,
  $s as postFundingAccounts,
  me as postHackathonAwaitParafinRepresentative,
  ue as postHackathonCreatePilot,
  pe as postHackathonGenerateOffers,
  fe as postHackathonGmv,
  Pe as postHackathonMapOffersToEmails,
  oe as postHackathonPartners,
  he as postHackathonReset,
  ge as postHackathonSendOffers,
  xe as postHackathonUploadSalesCsv,
  oo as postKycRetry,
  S_ as postManualRepayment,
  Bp as postMarkAccountingIntegrationSeen,
  Ae as postMarketingIntegrations,
  Be as postMarketingReview,
  Va as postMerchantBankAccounts,
  $p as postMerchantBankApplications,
  Mo as postMerchantBankBusiness,
  Gu as postMerchantBankCardActivate,
  Lu as postMerchantBankCardApplePay,
  oi as postMerchantBankCardDisputeChargebackCredit,
  ri as postMerchantBankCardDisputeStateTransition,
  ei as postMerchantBankCardDisputes,
  Xu as postMerchantBankCardGooglePay,
  zu as postMerchantBankCardStateTransition,
  Eu as postMerchantBankCards,
  gi as postMerchantBankExternalAccounts,
  ki as postMerchantBankExternalBankTransfers,
  $a as postMerchantBankInnerTransfers,
  W_ as postMerchantBankOffers,
  xu as postMerchantBankRecipientTransfers,
  _u as postMerchantBankRecipients,
  Pu as postMerchantBankRecipientsIdPaymentRails,
  r_ as postMfaOtpSendPersonidPhonenumbertype,
  o_ as postMfaOtpVerifyPersonidPhonenumbertype,
  N_ as postMfaVerifyAccountDetails,
  nc as postOfficerSubsidiaryRecords,
  Oa as postOptIn,
  Zm as postOptIns,
  ol as postOptOut,
  jt as postPartnerSyncWebhooks,
  vt as postPartnerUsers,
  tt as postPartners,
  ca as postRestriction,
  xp as postRutterConnections,
  ql as postSandboxAmazonLockBankAccount,
  Ol as postSandboxBreakBankAccount,
  ll as postSandboxCapitalProductPayOff,
  kl as postSandboxMerchantBankSetupBusiness,
  Kl as postSandboxRepayments,
  Ap as postSardineSessionId,
  Io as postSetupAmazonTopUp,
  Pt as postSlackAuthorize,
  Co as postSpd,
  ji as postTaxRecords,
  E_ as postTemplateTypes,
  l_ as postTemplates,
  Cc as postTermsOfServiceMarkShown,
  Fs as postTriggerRunCelticsChecklist,
  Yp as postWebhooksPlaidGeneral,
  nM as publicApiPaths,
  nr as putDataingestionS3DatasetIngestionConfigs,
  ul as putSandboxCapitalProductTestOffer,
  Fl as putSandboxFeeDiscount,
  Fd as resendCrmAttempt,
  qd as resendWebhookAttempt,
  fc as sendMarketingEmails,
  Qs as updateBankAccountInfo,
  Qy as updatePdPartnerEventUpdateUserSubscription,
  Wy as updatePdPartnerEventUpdateUserSubscriptionsForEventType,
  Hd as updatePdWebhookEndpoint,
  Yk as useAmazonRPCPreLogin,
  Jk as useAttemptAmazonBankVerification,
  Jb as useAttemptSync,
  Ab as useBatchCreatePdPartnerEventMarkAllNotificationsRead,
  zb as useBatchResendWebhookAttempt,
  tK as useBenchmarkGetCapitalProductOwnership,
  Pb as useCountPdBusinessTableRows,
  Mb as useCountPdCapitalProductApplicationRow,
  Rb as useCountPdCapitalProductOfferRow,
  xb as useCountPdCapitalProductRow,
  Ik as useCreateAttemptToUnderwriteBnplOrder,
  uk as useCreateAttemptToUnderwriteIntroductoryOffers,
  gK as useCreateBankAccountInfo,
  wk as useCreateBeneficialOwner,
  sK as useCreateCapitalProductIntents,
  iK as useCreateCapitalProductSuperApplicationActions,
  uK as useCreateCapitalProductSuperApplications,
  Lh as useCreateCapitalProducts,
  nK as useCreateOfferedCapitalProductFeeDiscount,
  FO as useCreateOrganization,
  kO as useCreatePartnerApiKey,
  px as useCreatePdFeeDiscountProgram,
  yx as useCreatePdFeeDiscountProgramAccessRequest,
  Db as useCreatePdPartnerEventMarkNotificationRead,
  rx as useCreatePdWebhookEndpoint,
  KK as useCreatePlaidInvestigation,
  tM as useCreatePlaidLinkage,
  ab as useCreateS3OfferCsv,
  ix as useCreateS3PartnerDashboardTableCsv,
  zk as useCreateUnderwritingAttempt,
  Hk as useCreateUnverifiedAmazonFundingAccount,
  Vk as useCreateUnverifiedFundingAccount,
  Zk as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  eM as useCreateZendeskTicket,
  ek as useDeleteAuthManagementUseridSamlSamlconfigid,
  Lk as useDeleteBeneficialOwner,
  dh as useDeleteBeneficialOwnersId,
  Ph as useDeleteBusinessOfficersId,
  gO as useDeleteCashAdvancePausesP1,
  ah as useDeleteMarketingIntegrationsVendor,
  wP as useDeleteMerchantBankExternalAccountsId,
  qP as useDeleteMerchantBankRecipientsId,
  NO as useDeleteOrganizationDeleteUsers,
  UO as useDeletePartnerDeleteUsers,
  LO as useDeletePartnerSyncWebhooksId,
  sx as useDeletePdWebhookEndpoint,
  vO as useDeleteSlackChannelsId,
  jk as useDisputeTransaction,
  Qb as useExperimentalCapitalProductOfferClosedSandbox,
  wb as useExperimentalCapitalProductOfferCreatedSandbox,
  ZP as useGetAccountingInfo,
  Rx as useGetAmazonFundingAccountLock,
  rb as useGetAnalyticsCubeToken,
  Jx as useGetAuthManagementP1Saml,
  lK as useGetBankAccountInfos,
  lh as useGetBanks,
  ch as useGetBeneficialOwners,
  cO as useGetBusinessAnnotations,
  uO as useGetBusinessCores,
  FK as useGetBusinessIdentities,
  hh as useGetBusinessOfficers,
  Oh as useGetBusinessTableRowsCard,
  fh as useGetBusinessTableRowsLoan,
  qh as useGetBusinessTableRowsMca,
  gh as useGetBusinessTableRowsProductAgnostic,
  JK as useGetBusinessesDailySalesRecords,
  db as useGetBusinessesNotifications,
  QK as useGetBusinessesProducts,
  Ak as useGetBusinessesShowTopUpOffer,
  cK as useGetCalculatedChunkValues,
  $h as useGetCapitalProductActivities,
  Xh as useGetCapitalProductApplicationSnapshots,
  zh as useGetCapitalProductApplications,
  rK as useGetCapitalProductIntents,
  Zh as useGetCapitalProductNetTopUpItemization,
  Vh as useGetCapitalProductOfferCollection,
  wh as useGetCapitalProductProspectiveTermsOfService,
  eK as useGetCapitalProductRepaymentSchedule,
  Jh as useGetCapitalProductStateDisclosure,
  Qh as useGetCapitalProducts,
  dk as useGetCardAccessToken,
  ik as useGetCardBalances,
  pk as useGetCardLifecycleStates,
  mk as useGetCardPendingTransactions,
  _k as useGetCardTransactions,
  ck as useGetCardholders,
  IK as useGetCards,
  dO as useGetCashAdvanceChargeOffs,
  mO as useGetCashAdvancePauses,
  aK as useGetCounteroffer,
  Sh as useGetDataingestionS3DatasetIngestionConfigs,
  Bh as useGetDataingestionS3DatasetIntegrations,
  Rh as useGetDataingestionS3DatasetUploadValidationResults,
  Fh as useGetDataingestionS3DatasetUploads,
  Eh as useGetDataingestionS3OrgDatasetIntegrations,
  Nh as useGetDataingestionStripeIngestions,
  zP as useGetDebtChecks,
  VP as useGetDebts,
  WK as useGetDocumentUploadGroup,
  HK as useGetDocumentUploadList,
  Nk as useGetEarlyManualRepaymentWhitelist,
  XP as useGetFinancialReviews,
  yK as useGetFlexLoanMinimumRepaymentSchedule,
  fK as useGetFundingAccounts,
  Dk as useGetFundingPlaidLinkToken,
  OO as useGetFutureActivities,
  zO as useGetHackathonGetPilot,
  bK as useGetKycRetryFields,
  ak as useGetLatestAttemptToUnderwriteIntroductoryOffers,
  XK as useGetLiens,
  uh as useGetMarketingGtmToolkit,
  sh as useGetMarketingIntegrations,
  ih as useGetMarketingParagonToken,
  aP as useGetMerchantBankAccounts,
  sP as useGetMerchantBankAccountsId,
  oP as useGetMerchantBankAccountsIdBalances,
  kx as useGetMerchantBankApplications,
  xx as useGetMerchantBankApplicationsId,
  FP as useGetMerchantBankCardAccessToken,
  BP as useGetMerchantBankCardCardholderAccessToken,
  kP as useGetMerchantBankCardCardholders,
  vP as useGetMerchantBankCardDisputes,
  PP as useGetMerchantBankCards,
  GP as useGetMerchantBankExternalAccounts,
  IP as useGetMerchantBankExternalAccountsId,
  NP as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  UP as useGetMerchantBankExternalAccountsPlaidLinkToken,
  _P as useGetMerchantBankInterest,
  Hx as useGetMerchantBankOffers,
  mP as useGetMerchantBankRecipients,
  gP as useGetMerchantBankRecipientsId,
  yP as useGetMerchantBankTransactions,
  cP as useGetMerchantBankTransactionsId,
  dP as useGetMerchantBankTransferFees,
  pP as useGetMerchantBankTransferLimits,
  Bx as useGetNaics,
  eb as useGetOfficerSubsidiaryRecords,
  Ih as useGetOnboardingBusinessRequirements,
  MK as useGetOnboardingInfoNeeded,
  kK as useGetOnboardingStates,
  qk as useGetOptIns,
  hk as useGetOptOutEligibility,
  lk as useGetOrder,
  BO as useGetOrganization,
  Ib as useGetOwnerAuth,
  _h as useGetOwnerIsBeneficialOwner,
  BK as useGetOwners,
  vx as useGetPADAgreement,
  tP as useGetParafinder,
  eP as useGetParafinderBusinessTypes,
  rP as useGetParafinderCapitalInfo,
  nP as useGetParafinderSummary,
  xO as useGetPartnerApiKey,
  TO as useGetPartnerConfigs,
  GO as useGetPartnerPublicConfigs,
  wO as useGetPartnerSyncWebhooks,
  SO as useGetPartnerUsers,
  Px as useGetPartnerWidgetConfigs,
  hO as useGetPartners,
  _O as useGetPartnersDoordashAdminUsers,
  PO as useGetPartnersUnderwriting,
  _x as useGetPdFeeDiscountProgram,
  cx as useGetPdFeeDiscountProgramAccessRequest,
  Eb as useGetPdOfferUrl,
  lx as useGetPdRevenueShare,
  Tb as useGetPdUtilityInfo,
  hK as useGetPlaidInvestigations,
  gk as useGetRefund,
  fO as useGetRepaymentPlanParameters,
  Sk as useGetRepayments,
  $K as useGetRestriction,
  YK as useGetRestrictionsDetails,
  pK as useGetRunOfacScreeningResults,
  fx as useGetRutterConnectionOptions,
  gx as useGetRutterConnections,
  ax as useGetS3PartnerDashboardTableCsv,
  hx as useGetSardineSessionId,
  EO as useGetSlackChannels,
  Uk as useGetStatements,
  nh as useGetStatementsV2,
  tx as useGetSync,
  ex as useGetSyncAndMaybeMarkFailed,
  Nx as useGetTemplateMock,
  Tx as useGetTemplateSnapshot,
  Gx as useGetTemplateSnapshots,
  Ix as useGetTemplateTypes,
  Ax as useGetTemplates,
  mK as useGetTermLoanProspectiveRepayments,
  Tk as useGetUnderwritingStatusForOrder,
  RO as useLinkPartner,
  sb as useListApiRequests,
  kh as useListDatasetUploads,
  Mh as useListManualDatasetUploadValidationResults,
  MO as useListOrganizations,
  cb as useListPartnerScheduledMarketingEmailConfigs,
  mb as useListPdBusinessDetailsApplications,
  lb as useListPdBusinessDetailsBankInfo,
  gb as useListPdBusinessDetailsBusinessInfo,
  fb as useListPdBusinessDetailsFundedProductDetails,
  Ob as useListPdBusinessDetailsFundedProductPaymentProgress,
  qb as useListPdBusinessDetailsFundedProducts,
  hb as useListPdBusinessDetailsOffers,
  Kb as useListPdBusinessDetailsOwnerInfo,
  bb as useListPdBusinessTableRows,
  Fb as useListPdCapitalProductApplicationRows,
  Bb as useListPdCapitalProductOfferRows,
  kb as useListPdCapitalProductRows,
  Xb as useListPdCrmDeliveryAttempts,
  Zb as useListPdCrmEventInfo,
  Yb as useListPdCrmEvents,
  Gb as useListPdCrmIntegrations,
  dx as useListPdFeeDiscountProgram,
  mx as useListPdFeeDiscountProgramCohortTableRows,
  vb as useListPdPartnerEventListUserSubscriptions,
  Sb as useListPdPartnerEventListWebNotifications,
  Cb as useListPdSupportInfo,
  nx as useListPdWebhookEndpoints,
  Vb as useListPdWebhookLogsDeliveryAttempts,
  Wb as useListPdWebhookLogsEventInfo,
  Lb as useListPdWebhookLogsEvents,
  ob as useListS3OfferCsvs,
  ux as useListS3PartnerDashboardTableCsvs,
  zx as useListSardineDetails,
  ub as useListSendMarketingEmailBatches,
  Uh as useListVersionedSchemas,
  Sx as useMarkPADAgreementSigned,
  Hb as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  yb as usePartnerScheduledMarketingEmailConfig,
  nk as usePatchAuthManagementUseridSamlSamlconfigid,
  Qk as usePatchBeneficialOwner,
  ph as usePatchBeneficialOwnersId,
  RK as usePatchBusinessIdentitiesId,
  bh as usePatchBusinessOfficersId,
  oK as usePatchCapitalProductIntents,
  Wh as usePatchCapitalProducts,
  yk as usePatchCardholdersCardholderid,
  qO as usePatchCashAdvancePausesP1,
  _K as usePatchClearWatchlistHitsId,
  Th as usePatchDataingestionStripeIngestions,
  UK as usePatchFlexLoanAge,
  GK as usePatchFlexLoanFund,
  NK as usePatchFlexLoanOwnership,
  jK as usePatchLiensId,
  xP as usePatchMerchantBankCardCardholdersId,
  QP as usePatchMerchantBankExternalAccountsId,
  WP as usePatchMerchantBankExternalBankTransfersId,
  Yx as usePatchMerchantBankMerchantConfig,
  KP as usePatchMerchantBankRecipientTransfersId,
  fP as usePatchMerchantBankRecipientsId,
  Vx as usePatchMfaExtendSession,
  mh as usePatchOwnerIsBeneficialOwner,
  CK as usePatchOwnersId,
  IO as usePatchPartnerConfigsId,
  WO as usePatchPartnerSyncWebhooksId,
  DO as usePatchPartnerUsers,
  bx as usePatchPartnerWidgetConfigs,
  bO as usePatchPartnersId,
  PK as usePatchPersonaFallbackInquiry,
  Gk as usePatchRoot,
  bk as usePatchSandboxCapitalProductFund,
  xk as usePatchSandboxCapitalProductFundingChecklist,
  kk as usePatchSandboxCapitalProductTopUp,
  Ck as usePatchSandboxRepayments,
  Ux as usePatchTemplates,
  JP as usePostAccountingInfo,
  SK as usePostAmazonRedirectUrl,
  ok as usePostAuthEmailOtpStart,
  sk as usePostAuthEmailOtpV2Verify,
  rk as usePostAuthEmailOtpVerify,
  jx as usePostAuthLoginStart,
  Xx as usePostAuthLoginValidateOtp,
  tk as usePostAuthManagementUseridSaml,
  Zx as usePostAuthTokenExchange,
  VO as usePostAuthTokens,
  yh as usePostBeneficialOwners,
  Ok as usePostBulkOptIn,
  EK as usePostBusiness,
  yO as usePostBusinessAnnotations,
  LK as usePostBusinessAuthLink,
  iO as usePostBusinessCores,
  Kh as usePostBusinessOfficers,
  pb as usePostBusinessesNotifications,
  Yh as usePostCapitalProductApplication,
  DK as usePostCapitalProductBusiness,
  jh as usePostCapitalProductNetTopUp,
  Hh as usePostCapitalProductOfferCollection,
  YP as usePostCardCloseAccount,
  wK as usePostCards,
  pO as usePostCashAdvanceChargeOffs,
  lO as usePostCashAdvancePauses,
  xh as usePostDataingestionOneschemaGenerateJwt,
  Ch as usePostDataingestionS3DatasetIntegrations,
  vh as usePostDataingestionS3OrgDatasetIntegrations,
  Dh as usePostDataingestionS3RunDatasetIngestion,
  Gh as usePostDataingestionStripeIngestions,
  HP as usePostDebts,
  $P as usePostDebtsCheckComplete,
  VK as usePostDocumentUploadGroup,
  jP as usePostFinancialReviews,
  Lx as usePostForgiveCapitalFee,
  OK as usePostFundingAccounts,
  XO as usePostHackathonAwaitParafinRepresentative,
  $O as usePostHackathonCreatePilot,
  YO as usePostHackathonGenerateOffers,
  ZO as usePostHackathonGmv,
  th as usePostHackathonMapOffersToEmails,
  HO as usePostHackathonPartners,
  JO as usePostHackathonReset,
  jO as usePostHackathonSendOffers,
  eh as usePostHackathonUploadSalesCsv,
  xK as usePostKycRetry,
  Qx as usePostManualRepayment,
  Ox as usePostMarkAccountingIntegrationSeen,
  oh as usePostMarketingIntegrations,
  rh as usePostMarketingReview,
  uP as usePostMerchantBankAccounts,
  Mx as usePostMerchantBankApplications,
  vK as usePostMerchantBankBusiness,
  MP as usePostMerchantBankCardActivate,
  RP as usePostMerchantBankCardApplePay,
  DP as usePostMerchantBankCardDisputeChargebackCredit,
  AP as usePostMerchantBankCardDisputeStateTransition,
  SP as usePostMerchantBankCardDisputes,
  EP as usePostMerchantBankCardGooglePay,
  CP as usePostMerchantBankCardStateTransition,
  bP as usePostMerchantBankCards,
  TP as usePostMerchantBankExternalAccounts,
  LP as usePostMerchantBankExternalBankTransfers,
  iP as usePostMerchantBankInnerTransfers,
  $x as usePostMerchantBankOffers,
  hP as usePostMerchantBankRecipientTransfers,
  lP as usePostMerchantBankRecipients,
  OP as usePostMerchantBankRecipientsIdPaymentRails,
  Cx as usePostMfaOtpSendPersonidPhonenumbertype,
  Ex as usePostMfaOtpVerifyPersonidPhonenumbertype,
  Wx as usePostMfaVerifyAccountDetails,
  nb as usePostOfficerSubsidiaryRecords,
  ZK as usePostOptIn,
  fk as usePostOptIns,
  Kk as usePostOptOut,
  QO as usePostPartnerSyncWebhooks,
  AO as usePostPartnerUsers,
  KO as usePostPartners,
  zK as usePostRestriction,
  qx as usePostRutterConnections,
  Fk as usePostSandboxAmazonLockBankAccount,
  Rk as usePostSandboxBreakBankAccount,
  Mk as usePostSandboxCapitalProductPayOff,
  Ek as usePostSandboxMerchantBankSetupBusiness,
  Bk as usePostSandboxRepayments,
  Kx as usePostSardineSessionId,
  TK as usePostSetupAmazonTopUp,
  CO as usePostSlackAuthorize,
  AK as usePostSpd,
  tb as usePostTaxRecords,
  wx as usePostTemplateTypes,
  Dx as usePostTemplates,
  _b as usePostTermsOfServiceMarkShown,
  dK as usePostTriggerRunCelticsChecklist,
  Fx as usePostWebhooksPlaidGeneral,
  Ah as usePutDataingestionS3DatasetIngestionConfigs,
  Pk as usePutSandboxCapitalProductTestOffer,
  vk as usePutSandboxFeeDiscount,
  jb as useResendCrmAttempt,
  $b as useResendWebhookAttempt,
  ib as useSendMarketingEmails,
  Wk as useSubmitKYC,
  qK as useUpdateBankAccountInfo,
  Xk as useUpdateBoostCompletion,
  Ub as useUpdatePdPartnerEventUpdateUserSubscription,
  Nb as useUpdatePdPartnerEventUpdateUserSubscriptionsForEventType,
  ox as useUpdatePdWebhookEndpoint,
  $k as useUploadBankStatements
};
