import styled from 'styled-components'

type FlexProps = {
  gap?: string
  wrap?: string
  padding?: string
  grow?: string
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ grow }) => grow && `flex-grow: ${grow};`}
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`

export const FlexCenter = styled(Flex)`
  align-items: center;
`

export const FlexSpaced = styled(FlexCenter)`
  justify-content: space-between;
`

export const FlexEnd = styled(FlexCenter)`
  justify-content: end;
`

export const Center = styled(FlexCenter)`
  justify-content: center;
`

export const BiFold = styled(Flex)`
  align-items: stretch;
  justify-content: start;
  max-width: 100%;
`
